import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiCall } from '../../../../middlewares/api';
import OrderListPage from '../../../../components/common/OrderListPage';
import arrayToBreadCrumbs from '../../../../utils/sms/arrayToBreadCrumbs';
import { updateSpinnerState } from '../../../../actions/spinner';
import { updateToastInfo } from '../../../../actions/settings';
import ConfirmationModal from '../../../../components/common/confirmationModal';
import { getColumns, sameorderItemsColumns } from '../utils';
import { ShowAssignModal } from './ShowAssignModal';
import {
  orderStatus,
  returnOrderStatus,
  cancelableStatus,
} from '../../../../constants/orderStatus';
import { isBelongsToSameInvoice, showShippingLabelDownload } from './utils';
import { apiCallConsts } from '../../../../constants/apiCallConsts';
import PurchaseDataModal from '../../../scheme-management/Components/IndividualScheme/PurchaseDataModal';
import ProductReleaseModal from '../../productsRelease/components/ProductReleaseModal';
import CancellationModal from './CancellationModal';
import UnableToGenerateModal from './UnableToGenerateModal';
import CancelEWayBillModal from './CancelExistingEWayBillModal';
import EnterEWayBillModal from './EnterEwayBillModal';
import UnableToGenerateAWBModal from './UnableToGenerateAWBModal';
import DynamicMultiInputModal from './DynamicMultiInputModal';
import { setPageNumberInStore } from '../../../../actions/tablePage';
import { Tab, Tabs } from '@themesberg/react-bootstrap';
import OrderDetails from './OrderDetails';
import DynamicTabs from './DyanamicTabs';

const OrderPage = (props) => {
  const {
    type,
    showFilter,
    bulkActionOptions,
    defaultConfirmationProps,
    confirmationModalProps,
    setConfirmationModalProps,
    isHyperLocal,
    isCustomerPickup,
    is3PLDelivery,
    uploadButtonProps,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeName = useSelector(
    (state) => state.user.preferences.storeName || ''
  );
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const zone = useSelector((state) => state?.user?.preferences?.zone || '');
  const userName = useSelector((state) => state?.user?.user?.username || '');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [updateMode, setUpdateMode] = useState('');
  const [ordersData, setOrdersData] = useState([]);
  const breadCrumbItems = arrayToBreadCrumbs([[t('Orders')], [t(type)]]);
  const isPreRegistered = type === orderStatus.preRegistered || false;
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [ordersStatusData, setOrderStatusData] = useState([]);
  const [showQcFailModal, setShowQcFailModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showEwaybill, setShowEwaybill] = useState(false);
  const [showCancelEwaybillModal, setShowCancelEwaybillModal] = useState(false);
  const [showEnterEwaybillModal, setEnterEwaybillModal] = useState(false);
  const [inputFields, setInputFields] = useState([]);
  const [showUnableToAwbModal, setShowUnableToAwbModal] = useState(false);
  const [queryObject, setQueryObject] = useState({});
  const [queryParam, setQueryParam] = useState({});
  const [activeTab,setActiveTab] = useState("Orders List")
  const [tabsToRender,setTabsToRender] = useState([])
  const messageDisplayer = (type, title, message) => {
    dispatch(
      updateToastInfo({
        show: true,
        type: type,
        title: t(title),
        message: t(message),
      })
    );
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const fetchOrders = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        isPreRegistered
          ? `/internal/order/${orderStatus.preRegistered}`
          : `/internal/order`,
        {
          ...queryParam,
          storeCode: isAdmin ? queryParam?.storeCode || '' : storeName,
          zone: isAdmin
            ? Array.isArray(queryParam?.zone)
              ? queryParam?.zone?.join(',')
              : queryParam?.zone || ''
            : zone,
          status: type,
          isHyperLocal,
          isCustomerPickup,
          is3PLDelivery,
        }
      );
      if (res) {
        dispatch(setPageNumberInStore({ id: `${type}orders`, page: 1 }));
      }

      let tempData = res;
      if (type == orderStatus?.awaitingInvoice) {
        tempData = tempData?.filter(
          (each) => each?.status == orderStatus?.awaitingInvoice
        );
      }

      setOrdersData(tempData);
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'Orders fetched successfully');
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'Failed to fetch Orders', error.message);
    }
  };

  const updateOrders = async (data) => {
    await apiCall('put', `/internal/order/update`, data);
  };

  const onRefreshClick = () => {
    fetchOrders();
    setSelectedOrders([]);
  };

  const onRowSelect = (selected, objectId) => {
    if (selected) {
      setSelectedOrders((prev) => {
        const selectedItem = ordersData.find(
          (item) => item.objectId === objectId
        );
        return [...prev, selectedItem];
      });
    } else {
      setSelectedOrders((prev) =>
        prev.filter((item) => item.objectId !== objectId)
      );
    }
    setOrdersData((prev) =>
      prev.map((item) =>
        item.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };

  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleShowAssignModal = () => {
    setShowAssignModal(true);
  };

  const handleShowQCFailModal = () => {
    setShowQcFailModal(true);
  };

  const handleBulkUploadSubmit = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      await uploadButtonProps?.handleSubmit(data);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'failed to update orders!', error.message);
      throw new Error(error?.message);
    }
  };
  const isBelongsToSameStatus = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const orderNo = selectedOrders[0].orderNo;
      const store = isAdmin ? selectedOrders[0].storeCode : storeName;
      const result = await apiCall(
        apiCallConsts.GET_METHOD,
        `/internal/order?storeCode=${store}&orderNo=${orderNo}`
      );
      dispatch(updateSpinnerState(false));
      return result;
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'failed to update orders!', error.message);
    }
  };

  const handleSelectedRowsUpdate = async (actionId) => {
    if (selectedOrders?.length > 0) {
      if (
        selectedOrders?.length > 1 &&
        (actionId === 'awaitingInvoice' ||
          // actionId === 'ManualInvoice' ||
          actionId === 'invoiced' ||
          actionId === 'qcFail' ||
          actionId === 'accept' ||
          actionId === 'reject')
      ) {
        messageDisplayer(
          'danger',
          'Max 1 order to update',
          'Please select maximum of 1 order'
        );
        return;
      }
      if (selectedOrders?.length > 1 && actionId === 'cancelOrder') {
        const result = isBelongsToSameInvoice(selectedOrders);
        if (!result) {
          messageDisplayer(
            'danger',
            'Failed to update Orders',
            'please select same invoiced orders'
          );
          return;
        }
      }
      if (actionId === 'invoiced') {
        const result = await isBelongsToSameStatus();
        if (result?.length > 0) {
          setOrderStatusData(result);
          setShowStatusModal(true);
          return;
        } else {
          const sameOrderItems = ordersData.filter(
            (order) =>
              order.orderNo === selectedOrders[0].orderNo &&
              order.storeCode === selectedOrders[0].storeCode
          );
          const sameOrderItemIds = sameOrderItems.map((item) => item.serialNo);

          setOrderStatusData(sameOrderItemIds);
          setSelectedOrders(sameOrderItems);
        }
      }
      if (actionId === 'cancelOrder') {
        try {
          dispatch(updateSpinnerState(true));
          const result = await apiCall(
            apiCallConsts.GET_METHOD,
            `${apiCallConsts.ORDER_DETAILS_URL}${selectedOrders[0].objectId}`,
            {}
          );
          dispatch(updateSpinnerState(false));
          if (result[0]?.items) {
            setOrderStatusData(
              result[0]?.items?.filter(
                (item) => item.status !== orderStatus.cancelled
              )
            );
            setShowCancelModal(true);
            return;
          } else {
            setOrderStatusData(result[0]);
          }
        } catch (error) {
          messageDisplayer('danger', '', 'Error getting Order Data!');
        }
      }
      if (actionId === 'pickedUp') {
        const isCheckNeeded = showShippingLabelDownload(selectedOrders);

        if (isCheckNeeded) {
          const isShippingLabelDownloaded = selectedOrders.every(
            (order) => order.isShippingLabelDownloaded
          );
          if (!isShippingLabelDownloaded) {
            messageDisplayer(
              'danger',
              'Failed to update Orders',
              'please download shipping label before updating!'
            );
            return;
          }
        }
      }
      if (actionId === 'Generate Manifest') {
        const isAllHyperLocalOrders = selectedOrders.every(
          (order) => order.isHyperLocal
        );

        const isCourier = selectedOrders.every(
          (order) =>
            !order.isHyperLocal &&
            !order.isCustomerPickup &&
            !order?.isBiker &&
            !order?.isLarge &&
            !order?.isMedium
        );

        if (!isAllHyperLocalOrders && !isCourier) {
          messageDisplayer(
            'danger',
            'Failed to Generate Manifest',
            'Please select same Courier for manifest generation!'
          );
          return;
        }
      }
      handleActionSwitch(actionId);
    }
  };

  const handleActionSwitch = (actionId) => {
    switch (actionId) {
      case 'accept':
        setUpdateMode(orderStatus.accepted);
        handleShowDeleteModal();
        break;
      case 'reject':
        setUpdateMode(orderStatus.rejected);
        setConfirmationModalProps(defaultConfirmationProps['rejected']);
        handleShowDeleteModal();
        break;
      case 'awaitingInvoice':
        setUpdateMode(orderStatus.awaitingInvoice);
        setConfirmationModalProps(defaultConfirmationProps['awaitingInvoice']);
        handleShowDeleteModal();
        break;
      // case 'Manual Invoice':
      //   setUpdateMode(orderStatus.awaitingInvoice);
      //   setConfirmationModalProps(defaultConfirmationProps['awaitingInvoice']);
      //   handleShowDeleteModal();
      //   break;
      case 'invoiced':
        setUpdateMode(orderStatus.invoiced);
        setConfirmationModalProps(defaultConfirmationProps['invoiced']);
        handleShowDeleteModal();
        break;
      case 'readyForPickup':
        setUpdateMode(orderStatus.readyForPickup);
        handleShowDeleteModal();
        break;
      case 'Out Of Stock':
        setUpdateMode(orderStatus.outOfStock);
        handleShowDeleteModal();
        break;
      case 'To Be Invoiced':
        setUpdateMode('To Be Invoiced');
        handleShowDeleteModal();
        break;
      case 'pickedUp':
        setUpdateMode(orderStatus.pickedUp);
        handleShowDeleteModal();
        break;
      case 'Out For Delivery':
        setUpdateMode(orderStatus.outForDelivery);
        handleShowDeleteModal();
        break;
      case 'assign':
        setUpdateMode(orderStatus.accepted);
        handleShowAssignModal();
        break;
      case 'cancelOrder':
        setUpdateMode(orderStatus.cancelled);
        setShowCancelModal(true);
        break;
      case 'qcFail':
        setUpdateMode(orderStatus.qcfailed);
        setConfirmationModalProps(defaultConfirmationProps['qcFail']);
        handleShowQCFailModal();
        break;
      case 'refundInitated':
        setUpdateMode(returnOrderStatus.REFUND_INITIATED);
        handleShowDeleteModal();
        break;
      case 'On Hold':
        setUpdateMode('On Hold');
        handleShowDeleteModal();
        break;
      case 'Generate Manifest':
        setUpdateMode('Generate Manifest');
        handleShowDeleteModal();
        break;
      case 'stopCancellation':
        const prevStatus =
          selectedOrders[0]?.actionLog[selectedOrders[0]?.actionLog?.length - 2]
            ?.action;
        const isCancellable = cancelableStatus.includes(prevStatus);
        if (!isCancellable) {
          messageDisplayer(
            'danger',
            'Failed',
            'Cannot Stop Cancellation, Invoice Already Generated'
          );
          break;
        }
        setUpdateMode(prevStatus);
        handleShowDeleteModal();
        break;
      case 'delivered':
        setUpdateMode(orderStatus.delivered);
        setConfirmationModalProps(defaultConfirmationProps['delivered']);
        handleShowDeleteModal();
        break;
      default:
        break;
    }
  };

  const generateManifest = async (data) => {
    const responseData = await apiCall(
      apiCallConsts?.PUT_METHOD,
      '/internal/order/manifest/generate',
      data
    );

    const url = responseData?.url;

    if (url) {
      const response = await fetch(url);
      const labelBlob = await response.blob();
      const labelURL = URL.createObjectURL(labelBlob);
      const printWindow = window.open(labelURL, '_blank');
      printWindow.onload = () => {
        printWindow.print();
      };
    } else {
    }
  };
  const handleOnConfirmClick = async (data) => {
    try {
      if (updateMode == 'Generate Manifest') {
        dispatch(updateSpinnerState(true));
        await generateManifest(data);
        dispatch(updateSpinnerState(false));
      } else {
        dispatch(updateSpinnerState(true));
        await updateOrders(data);
        await fetchOrders();
        setEnterEwaybillModal(false);
        dispatch(updateSpinnerState(false));
        messageDisplayer('success', '', 'Update Successful!');
        setShowDeleteModal(false);
        if (updateMode === 'Accepted') {
          setShowAssignModal(false);
        }
        // if (updateMode === 'On Hold') {
        // }
        if (updateMode === orderStatus.qcfailed) {
          setShowQcFailModal(false);
        }
        setShowCancelModal(false);
        setSelectedOrders([]);
      }
      defaultConfirmationProps && setConfirmationModalProps([]);
    } catch (e) {
      dispatch(updateSpinnerState(false));

      console.log(e?.message?.includes('604'));
      if (e?.message?.includes('604')) {
        setShowDeleteModal(false);
        setShowEwaybill(true);
      }
      if (e?.message?.toLowerCase()?.includes('not serviceable')) {
        setShowDeleteModal(false);
        dispatch(updateSpinnerState(false));

        setShowUnableToAwbModal(true);
      }
      messageDisplayer('danger', 'Cannot be updated!', e.message);
    }
  };
  const handleModalOnConfirmClick = (storeCode ,isForceAccept) => {
    const selectedOrdersData = {
      id: selectedOrders.map((item) => item.objectId),
      status: updateMode,
      username: userName,
      isForceAccept
    };

    const warehouseName = selectedOrders[0]?.storeCode

    // console.log(confirmationModalProps, updateMode);
    if (type !== orderStatus.cancelled) {
      switch (updateMode) {
        case orderStatus.rejected:
          selectedOrdersData.reasonForRejection =
            confirmationModalProps[0].value[0];
          selectedOrdersData.remarks = confirmationModalProps[0].remarks;
          break;
        case orderStatus.awaitingInvoice:
          selectedOrdersData.serialNo = confirmationModalProps[0].value;
          break;
        // case 'To Be Invoiced':
        // console.log('inside to be invoiced');
        // selectedOrdersData.serialNo = confirmationModalProps[0].value;
        // break;
        case orderStatus.invoiced:
          selectedOrdersData.invoiceNo = confirmationModalProps[0]?.value;
          selectedOrdersData.ewaybillNo = confirmationModalProps[0]?.ewaybillNo;
          selectedOrdersData.awbNumber = confirmationModalProps[0]?.awbNumber;
          break;
        case orderStatus.accepted:
          selectedOrdersData.storeCode =
            storeCode[0] || selectedOrders[0].storeCode;
          break;
        case orderStatus.cancelled:
          selectedOrdersData.SRTNo = confirmationModalProps[0].value;
          break;
        case orderStatus.qcfailed:
          selectedOrdersData.serialNo = confirmationModalProps[0].value;
          break;
        case 'Generate Manifest':
          selectedOrdersData.awbNumbers = selectedOrders.map(
            (item) => item?.awbNumber
          );
          break;
        case orderStatus.delivered:
          selectedOrdersData.deliveredDate = confirmationModalProps[0].value;
          selectedOrdersData.isSelfPickup =
            confirmationModalProps[1].value === 'yes';
          break;
        default:
          break;
      }
      // if (updateMode === orderStatus.invoiced) {
      //   const allSameOrderIds = ordersData
      //     .filter((order) => ordersStatusData.includes(order.serialNo))
      //     .map((order) => order.objectId);
      //   selectedOrdersData.id = [...allSameOrderIds];
      // }
      if (type === orderStatus.pendingAction) {
        selectedOrdersData.isForceAccept = true;
      }
    }
    handleOnConfirmClick(selectedOrdersData);
  };

  const handleCancelModalConfirmClick = (data, formInput) => {
    let result = {};
    let cancelledIds = [];
    let invoicedIds = [];
    if (data?.length === 1) {
      cancelledIds = [data[0].objectId];
    } else {
      cancelledIds = data
        .filter((item) => item.status === orderStatus.cancelled)
        .map((item) => item.objectId);

      invoicedIds = data
        .filter((item) => item.status !== orderStatus.cancelled)
        .map((item) => item.objectId);
    }
    if (formInput.invoiceNumber && invoicedIds?.length > 0) {
      result = {
        id: [...cancelledIds, ...invoicedIds],
        cancelledId: cancelledIds,
        invoicedId: invoicedIds,
        status: orderStatus.cancelled,
        username: userName,
        SRTNo: formInput.srtNumber,
        remarks: formInput?.remarks || '',
        reason : formInput?.reason,
        invoiceNo: formInput.invoiceNumber,
      };
    } else {
      result = {
        id: cancelledIds,
        status: orderStatus.cancelled,
        username: userName,
        SRTNo: formInput.srtNumber,
        remarks: formInput?.remarks || '', reason : formInput?.reason,
      };
    }

   
    handleOnConfirmClick(result);
  };

  const handleTimeOut = async (id) => {
    try {
      const data = {
        id: [id],
        status: orderStatus.autoRejected,
      };
      // await updateOrders(data);
      // setOrdersData((prev) =>
      //   prev.filter(
      //     (order) =>
      //       order.isBiker || order.isHyperLocal || order.objectId !== id
      //   )
      // );
      // await fetchOrders();
    } catch (error) {
      messageDisplayer('danger', 'Failed to handle timeout', error.message);
    }
  };

  useEffect(() => {
    const newQueryObject = {};
    queryParams.forEach((value, key) => {
      queryObject[key] = value;
    });

    if (Object.keys(newQueryObject).length > 0) {
      setQueryObject(newQueryObject);
    }
  }, [location.search]);
  useEffect(() => {
    if (Object.keys(queryObject).length > 0) {
      console.log(queryObject, 'this is to type state');
      setQueryParam({ ...queryObject, status: type });
    }
  }, [queryObject, isAdmin]);

  // Fetch orders when queryParam changes
  useEffect(() => {
    const toSearch = Object.values(queryParam).filter(
      (each) => each && each != type
    );
    if (
      !isAdmin ||
      (toSearch?.length > 0 &&
        queryParam &&
        Object.keys(queryParam || {}).length > 0)
    ) {
      fetchOrders();
    } else {
      setOrdersData([]);
    }
  }, [queryParam]);

  return (
    <>
   <DynamicTabs  activeTab={activeTab}
  setActiveTab = {setActiveTab}
  selectedOrders={selectedOrders}
  type = {type}
  showFilter={showFilter}
  ordersData={ordersData}
  getColumns={getColumns}
  onRowSelect={onRowSelect}
  navigate={navigate}
  handleTimeOut={handleTimeOut}
  bulkActionOptions={bulkActionOptions}
  isAdmin={isAdmin}
  onRefreshClick={onRefreshClick}
  t={t}
  setTabsToRender={setTabsToRender}
  breadCrumbItems={breadCrumbItems}
  handleSelectedRowsUpdate={handleSelectedRowsUpdate}
  setOrdersData={setOrdersData}
  setQueryParam={setQueryParam}
  uploadButtonProps={uploadButtonProps}
  handleBulkUploadSubmit={handleBulkUploadSubmit}
  tabsToRender={tabsToRender} />
      <ConfirmationModal
        showModal={showDeleteModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowDeleteModal(false);
          defaultConfirmationProps && setConfirmationModalProps([]);
        }}
        modalBody={t(
          type === orderStatus.accepted &&
            updateMode !== orderStatus.rejected &&
            updateMode !== 'On Hold'
            ? `Please Enter Serial No for QC Passed Items`
            : (type === orderStatus.awaitingInvoice ||
                type == 'Manual Invoice') &&
              ordersStatusData.length > 1
            ? `These Items Are Moving To Next Stage, They Belongs To Same Order!  
                Serial No's are:  ${ordersStatusData.join(', ')}`
            : `Are you sure you want to change the status to ${updateMode}?`
        )}
        modalTitle={t(`${updateMode} Order(s)`)}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />
      {showAssignModal && (
        <ShowAssignModal
          showModal={showAssignModal}
          onConfirm={handleModalOnConfirmClick}
          closeModal={() => setShowAssignModal(false)}
          modalTitle={t(`${updateMode} Order(s)`)}
        />
      )}
      <PurchaseDataModal
        showModal={showStatusModal}
        closeModal={() => setShowStatusModal(false)}
        data={ordersStatusData}
        columns={sameorderItemsColumns(isAdmin)}
        modalTitle={'Action Need To Be Taken'}
        showRowBgColor={true}
      />

      <UnableToGenerateModal
        show={showEwaybill}
        handleClose={() => {
          setShowEwaybill(!showEwaybill);
        }}
        onCancel={() => {
          setShowEwaybill((prev) => !prev);
          setShowCancelEwaybillModal((prev) => !prev);
        }}
        onSuccess={() => {
          setShowEwaybill((prev) => !prev);
          handleShowDeleteModal(false);
          handleActionSwitch('invoiced');
          setEnterEwaybillModal((prev) => !prev);
          setInputFields([
            {
              key: 'ewaybillNo',
              placeholder: 'Enter E-Way Bill Number',
              validationLength: 5,
            },
          ]);
        }}
      />
      <CancelEWayBillModal
        show={showCancelEwaybillModal}
        handleClose={() => {
          setShowCancelEwaybillModal((prev) => !prev);
        }}
        onCancel={() => {
          setShowEwaybill((prev) => !prev);

          setShowCancelEwaybillModal(!showCancelEwaybillModal);
        }}
        onSuccess={() => {
          handleModalOnConfirmClick();
        }}
      />

      <UnableToGenerateAWBModal
        show={showUnableToAwbModal}
        handleClose={() => {
          setShowUnableToAwbModal((prev) => false);
        }}
        onCancel={() => {
          setShowUnableToAwbModal(false);
        }}
        onSuccess={() => {
          setEnterEwaybillModal(true);
          setShowUnableToAwbModal(false);
          setInputFields([
            {
              key: 'awbNumber',
              placeholder: 'Enter AWB Number',
              validationLength: 12,
            },
          ]);
        }}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />

      <DynamicMultiInputModal
        show={showEnterEwaybillModal}
        handleClose={() => {
          setEnterEwaybillModal(false);
        }}
        onSuccess={() => {
          handleModalOnConfirmClick();
          setShowDeleteModal(false);
        }}
        onCancel={() => {
          setEnterEwaybillModal(false);
        }}
        inputFields={inputFields}
        setConfirmationModalProps={setConfirmationModalProps}
      />

      <ProductReleaseModal
        showModal={showQcFailModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowQcFailModal(false);
          defaultConfirmationProps && setConfirmationModalProps([]);
        }}
        modalBody={t(`please Enter Serial No for QC Failed Items`)}
        modalTitle={t(`${updateMode} Order(s)`)}
        ModalProps={confirmationModalProps}
        setModalProps={setConfirmationModalProps}
        allowOptionalValues={true}
      />
      <CancellationModal
        data={ordersStatusData}
        showModal={showCancelModal && ordersStatusData.length > 0}
        closeModal={() => setShowCancelModal(false)}
        onConfirm={handleCancelModalConfirmClick}
      />
    </>
  );
};
export default OrderPage;
