import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  markParcelAsDisabled,
  setParcelsForStagesInStore,
} from '../../actions/parcels';
import { updateToastInfo } from '../../actions/settings';
import { updateSpinnerState } from '../../actions/spinner';
import DefectivePickupRescheduleModal from '../../components/common/defectivePickupPendingSchedule';
import ParcelStatusPage from '../../components/common/parcelStatusPages';
import {
  createDefectivePickupPendingParcel,
  getParcelsByStatusAndSubStatus,
} from '../../parse-functions/parcels';
import { Routes } from '../../router/routes';
import { convertArrayToObj } from '../../utils/json';
import { returnWarehouseDropdownOptions } from '../../utils/warehouse';
import { setFiltersInStore } from '../../actions/filters';
import { returnEndOfDay, returnStartOfDay } from '../../utils/datetime';
import { replacementPendingTableColumns } from './utils';

const ReplacementPendingPickupList = () => {
  const pageStatus = 'defectivepending';
  const otherStatus = 'defectivepicked';
  const { t } = useTranslation();

  const breadCrumbItems = [
    {
      title: t('Def. Delivered / Repl. Pending'),
      link: Routes.Replacement.PickupPending.path,
    },
  ];
  const dispatch = useDispatch();

  const [selectedParcelId, setSelectedParcelId] = useState(null);
  const [errors, setErrors] = useState([]);
  const storeCode = useSelector(
    (state) => state?.user?.warehouse?.warehouseCode || ''
  );
  const filtersValue = useSelector((state) => state.filters?.[pageStatus]);
  const parcels = useSelector((state) => state?.parcels?.[pageStatus] || {});
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const warehouse = useSelector((state) => state?.user?.warehouse || {});
  const otherWarehouses = useSelector(
    (state) => state?.user?.otherWarehousesInZone || {}
  );
  const zone = useSelector((state) => state?.user?.warehouse?.zone || '');

  const handleFiltersSubmit = (startDate, endDate) => {
    dispatch(
      setFiltersInStore({
        pageType: pageStatus,
        data: {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        },
      })
    );
    getAvailableParcels(startDate, endDate);
  };

  const getAvailableParcels = async (startDate, endDate) => {
    dispatch(updateSpinnerState(true));
    try {
      const filters = {
        subStatus: [pageStatus, otherStatus],
        storeCode,
        isRescheduled: 'false',
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        zone,
      };
      const parcels = await getParcelsByStatusAndSubStatus(filters);
      dispatch(
        setParcelsForStagesInStore(
          pageStatus,
          convertArrayToObj(JSON.parse(JSON.stringify(parcels)), 'objectId')
        )
      );
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('Orders fetched'),
          message: t(
            'You can schedule your parcels here for which defective is pending'
          ),
        })
      );
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error'),
          message: t(e.message),
        })
      );
    }
  };
  const onRowSelect = (value, objectId) => {
    if (selectedParcelId) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Only 1 item allowed'),
          message: t('Please deselect other item before choosing this'),
        })
      );
    } else {
      if (value) {
        setSelectedParcelId(objectId);
      } else {
        setSelectedParcelId(null);
      }
    }
  };
  const onRefreshClick = () => {
    const currentStartDate = filtersValue.startDate
      ? new Date(filtersValue.startDate)
      : returnStartOfDay(new Date());
    const currentEndDate = filtersValue.endDate
      ? new Date(filtersValue.endDate)
      : returnEndOfDay(new Date());
    getAvailableParcels(currentStartDate, currentEndDate);
  };
  const handleShowRescheduleModal = () => {
    setShowRescheduleModal(false);
  };
  const onRescheduleConfirm = async (data) => {
    dispatch(updateSpinnerState(true));
    try {
      const formData = {
        ...data,
        warehouse,
        parcelObjectId: selectedParcelId,
        newDate: new Date(),
      };
      await createDefectivePickupPendingParcel(formData);
      dispatch(markParcelAsDisabled(pageStatus, selectedParcelId));
      if (
        formData?.extraParcelIds &&
        Object.keys(formData?.extraParcelIds)?.length > 0
      ) {
        // eslint-disable-next-line no-unused-expressions
        formData?.extraParcelIds?.forEach((extraId) => {
          dispatch(markParcelAsDisabled(pageStatus, extraId));
        });
      }
      setShowRescheduleModal(false);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('Defective Pending Scheduled'),
          message: t(
            "This parcel's Defective has been rescheduled for the chosen date"
          ),
        })
      );
      dispatch(updateSpinnerState(false));
    } catch (error) {
      const detailsMatch = error.message.match(/Details: (.+)$/);
      console.log(error, detailsMatch);
      if (detailsMatch) {
        const details = JSON.parse(detailsMatch[1]); // Parse the JSON string
        console.log('details', details);
        setErrors(details);
      }
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Defective Pending Scheduled Failed'),
          message: t('Failed to Update!'),
        })
      );
    }
  };
  const handleItemBulkUpdate = (actionId) => {
    if (selectedParcelId) {
      switch (actionId) {
        case 'schedule': {
          setShowRescheduleModal(true);
          break;
        }
        default: {
          dispatch(
            updateToastInfo({
              show: true,
              type: 'danger',
              title: t('Select an Action'),
              message: t('Please select an action from the dropdown'),
            })
          );
          break;
        }
      }
    } else {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Select Item'),
          message: t('Please select an item to take action'),
        })
      );
    }
  };
  return (
    <>
      <ParcelStatusPage
        pageType={pageStatus}
        filtersValue={filtersValue}
        filterSubmit={handleFiltersSubmit}
        tableData={parcels}
        tableColumns={replacementPendingTableColumns(onRowSelect)}
        handleRefresh={onRefreshClick}
        pageTitle={t('Defective Delivered / Replacement Pending')}
        breadCrumbItems={breadCrumbItems}
        pageSubTitle={t(
          'All the parcels for which the pickup is still pending can be rescheduled here'
        )}
        searchPlaceHolder={t('Search by Invoice No. in Database')}
        handleItemBulkUpdate={handleItemBulkUpdate}
        bulkActionOptions={[
          {
            label: 'Schedule',
            value: 'schedule',
          },
        ]}
        enableBulkActions={selectedParcelId && Object.keys(parcels)?.length > 0}
      />
      <DefectivePickupRescheduleModal
        disableTargetDropdown={false}
        targetDropdownOptions={returnWarehouseDropdownOptions(otherWarehouses)}
        selectedParcelId={selectedParcelId}
        pageStatus={pageStatus}
        showModal={showRescheduleModal}
        closeModal={handleShowRescheduleModal}
        getNewSerialNo
        onConfirm={onRescheduleConfirm}
        errors={errors}
      />
    </>
  );
};
export default ReplacementPendingPickupList;
