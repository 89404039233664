import { Button, Form } from '@themesberg/react-bootstrap';
import { returnUserReadableDate } from '../../utils/datetime';
import { getTableRowCheckBox } from '../../utils/table';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faRemove } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-regular-svg-icons';

export const inputValidate = (data, setFormErrors) => {
  const errors = {};
  let isValid = true;
  if (!data.fromDate) {
    errors['fromDate'] = 'Cannot be Empty';
    isValid = false;
  }
  if (!data.toDate) {
    errors['toDate'] = 'Cannot be Empty';
    isValid = false;
  }
  if (data.toDate < data.fromDate) {
    errors['toDate'] = 'Cannot be before From Date';
    isValid = false;
  }
  if (data.vehicleNumber.length > 11) {
    errors['vehicleNumber'] = 'Length cannot be more than 11';
    isValid = false;
  }
  if (!data.vehicleNumber && !data.vendorName) {
    errors['vendorName'] = 'Both cannot be Empty!';
    errors['vehicleNumber'] = 'Both cannot be Empty!';
    isValid = false;
  }
  setFormErrors(errors);
  return isValid;
};

export const paymentInputValidate = (formInput, setFormErrors) => {
  const errors = {};
  let isValid = true;
  if (!formInput.paymentMethod) {
    errors['paymentMethod'] = 'Cannot be Empty!';
    isValid = false;
  }
  if (!formInput.invoiceNumber) {
    errors['invoiceNumber'] = 'Cannot be Empty!';
    isValid = false;
  }

  if (!formInput.invoiceDate) {
    errors['invoiceDate'] = 'Cannot be Empty!';
    isValid = false;
  }
  if (!formInput.paymentDate) {
    errors['paymentDate'] = 'Cannot be Empty!';
    isValid = false;
  }
  if (!formInput.paymentRemarks) {
    errors['paymentRemarks'] = 'Cannot be Empty!';
    isValid = false;
  }
  setFormErrors(errors);
  return isValid;
};
export const perDeliveryColumns = ({
  onRowSelect,
  isModal,
  handleInputChange,
  isFinalTextField,
  isDelete,
  setFunction,
  isdisabled,
  isPaid,
  isEdit,
  editFunction,
}) => {
  const columns = [
    {
      dataField: 'tripNumber',
      text: 'Trip No.',
    },
    {
      dataField: 'driverInfo',
      text: 'Vendor Name',
      formatter: (cell, row) => {
        return <div className="d-block">{cell.vendorName}</div>;
      },
    },
    {
      dataField: 'driverInfo',
      text: 'Vehicle Details',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div>{cell?.vehicleNumber}</div>
            <div>{cell?.paymentMode}</div>
            <div>{cell?.vehicleType}</div>
          </div>
        );
      },
    },
    {
      dataField: 'deliveryRateInfo',
      text: 'Deliveries',
      formatter: (cell, row) => {
        return (
          <>
            {['monthlyFixed', 'extraVehicle'].includes(
              row.driverInfo.paymentMode
            ) ? (
              <div className="d-block">
                <div>{`Quantity - ${
                  cell?.deliveryQuantity || cell?.gtCount || 0
                }`}</div>
                <div>{`DroppedAtHub - ${cell?.droppedAtHub || 0}`}</div>
                <div>{`Display - ${cell?.gtCount || 0}`}</div>
              </div>
            ) : (
              <div className="d-block">
                <div>{`Quantity - ${
                  cell?.deliveryQuantity || cell?.gtCount || 0
                }`}</div>
                <div>{`Defective - ${cell?.defectivePicked || 0}`}</div>
                <div>{`Packages - ${cell?.package || 0}`}</div>
              </div>
            )}
          </>
        );
      },
    },
    {
      dataField: 'tripDate',
      text: 'Trip Date',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            {returnUserReadableDate(row.dispatchedAt)}
          </div>
        );
      },
    },
    {
      dataField: 'totalAmount',
      text: 'Total Amount',
    },
  ];
  if (!isModal) {
    columns.splice(0, 0, {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    });
  }
  if (isFinalTextField) {
    columns.push({
      dataField: 'finalPayment',
      text: 'Final Payment',
      formatter: (cell, row) => {
        return (
          <Form.Group>
            <Form.Control
              type="number"
              id={row.objectId}
              value={cell}
              onChange={(e) => handleInputChange(row.objectId, e)}
              disabled={isdisabled}
            />
          </Form.Group>
        );
      },
    });
  }

  if (isPaid) {
    columns.push({
      dataField: 'finalPayment',
      text: 'Final Payment',
    });
  }

  if (isDelete) {
    columns.push({
      dataField: 'objectId',
      text: '',
      formatter: (cell, row) => {
        return (
          <Button
            onClick={() => {
              setFunction((prev) =>
                prev.filter((item) => item.objectId !== cell)
              );
            }}
          >
            <FontAwesomeIcon icon={faRemove} />
          </Button>
        );
      },
    });
  }

  if (isEdit) {
    columns.push({
      dataField: 'edit',
      text: 'Edit',
      formatter: (cell, row) => {
        return (
          <Button onClick={() => editFunction(row?.tripNumber)}>
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        );
      },
    });
  }
  return columns;
};
export const unpaidTripsColumns = ({ onRowSelect, handleViewClick }) => {
  const columns = [
    {
      dataField: 'date',
      text: 'Date',
    },
    {
      dataField: 'vehicleDetails',
      text: 'Vehicle Number',
      formatter: (cell, row) => {
        return (
          <>
            <div>{cell?.vehicleNumber}</div>
            <div>{cell?.vehicleType}</div>
          </>
        );
      },
    },
    {
      dataField: 'vehicleDetails.vendorName',
      text: 'Vendor',
      formatter: (cell, row) => {
        return cell;
      },
    },
    {
      dataField: 'attendance',
      text: 'Attendance',
      formatter: (cell, row) => {
        return (
          <>
            <div>
              Days Present: {`${row.totalPresent || 0}/${row.totalDays || 0}`}
            </div>
            <div>Trips: {row.totalTrips}</div>
            <div>Pending Trips: {row.pendingTrips}</div>
          </>
        );
      },
    },
    {
      dataField: 'vehicleDetails.paymentMode',
      text: 'Payment Details',
      formatter: (cell, row) => {
        return (
          <>
            <div>Type: {cell}</div>
            <div>
              Rate:
              {row.deliveryAmount > 1
                ? row.deliveryAmount.join(',')
                : row?.deliveryAmount?.[0]}
            </div>
          </>
        );
      },
    },
    {
      dataField: 'deliverySummary',
      text: 'Delivery Summary',
      formatter: (cell, row) => {
        return (
          <>
            <div>Delivered: {row.totalDeliveryQuantity}</div>
            <div>Delivery Attempted: {row.totalDeliveryAttempted}</div>
            <div>Dropped At Hub: {row.droppedAtHub}</div>
          </>
        );
      },
    },
    {
      dataField: '',
      text: 'Display/GT Summary',
      formatter: (cell, row) => {
        return (
          <>
            <div>Total Display: {row.totalDisplay}</div>
            <div>Display/GT Products: {row.totalDisplayProducts}</div>
          </>
        );
      },
    },
    {
      dataField: 'totalAmount',
      text: 'Total Amount',
      formatter: (cell, row) => {
        return (
          <>
            <div>Delivery: {cell?.toFixed(2) || 0}</div>
            <div>Voucher: {row.voucherAmount?.toFixed(2) || 0}</div>
            <div>
              Total: {((cell || 0) + (row.voucherAmount || 0)).toFixed(2)}
            </div>
          </>
        );
      },
    },
    {
      dataField: 'view',
      text: 'View',
      formatter: (cell, row) => {
        return (
          <FontAwesomeIcon
            icon={faEye}
            onClick={() => handleViewClick(row.objectId)}
          />
        );
      },
    },
  ];
  if (onRowSelect) {
    columns.splice(0, 1, {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    });
  }
  return columns;
};

export const paidTripsColumns = () => {
  return [
    {
      dataField: 'invoiceNumber',
      text: 'Invoice No.',
    },
    {
      dataField: 'invoiceDate',
      text: 'Invoice Date',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            {returnUserReadableDate(row.invoiceDate)}
          </div>
        );
      },
    },
    {
      dataField: 'vendorName',
      text: 'Vendor Name',
    },
    {
      dataField: 'totalTrips',
      text: 'Total Trips',
    },
    {
      dataField: 'totalAmount',
      text: 'Total Amount',
    },
    {
      dataField: 'finalPayment',
      text: 'Final Payment',
    },
  ];
};

export const paidInputValidate = (data, setFormErrors) => {
  let isValid = true;
  let errors = {};
  if (!data.invoiceNumber && !data.vendorName) {
    errors['vendorName'] = 'Both cannot be Empty!';
    errors['invoiceNumber'] = 'Both cannot be Empty!';
  }
  if (!data.fromDate && !data.invoiceNumber) {
    errors['fromDate'] = 'Cannot be Empty';
    errors['invoiceNumber'] = 'Both cannot be Empty!';
    isValid = false;
  }
  if (!data.toDate && !data.invoiceNumber) {
    errors['toDate'] = 'Cannot be Empty';
    errors['invoiceNumber'] = 'Both cannot be Empty!';
    isValid = false;
  }
  if (data.toDate < data.fromDate) {
    errors['toDate'] = 'Cannot be before From Date';
    isValid = false;
  }
  setFormErrors(errors);
  return isValid;
};

export const getOrderCount = (num) => {
  if (num <= 2) return 1;
  const total = Math.ceil((num - 2) / 3) + 1;
  return total;
};

export const tripsViewColumns = ({
  handleEditClick,
  handleSaveClick,
  onTripViewClick,
}) => {
  const columns = [
    {
      dataField: 'tripDetails',
      text: 'Trip Details',
      formatter: (cell, row) => {
        return (
          <>
            <div>
              <span>
                No: {row.tripNumber}
                {'  '}
              </span>
              <FontAwesomeIcon
                icon={faEye}
                onClick={() => onTripViewClick(row.objectId)}
              />
            </div>
            <div>Date: {row.date}</div>
          </>
        );
      },
    },
    {
      dataField: 'paymentMode',
      text: 'Payment Mode',
    },
    {
      dataField: 'totalDeliveryAttempted',
      text: 'Del. Attempted',
    },
    {
      dataField: 'totalDeliveryQuantity',
      text: 'Delivered',
    },
    {
      dataField: 'droppedAtHub',
      text: 'DroppedAtHub',
    },
    {
      dataField: 'totalDisplay',
      text: 'GT',
    },
    {
      dataField: 'totalDisplayProducts',
      text: 'GT Products',
    },
    {
      dataField: 'totalAmount',
      text: 'Trip Amount',
    },
    {
      dataField: 'voucherAmount',
      text: 'Vouchers',
    },
    {
      dataField: '',
      text: handleSaveClick ? 'Edit' : 'View',
      formatter: (cell, row) => {
        return (
          <FontAwesomeIcon
            icon={faEdit}
            onClick={() => handleEditClick(row.objectId)}
          />
        );
      },
    },
  ];
  return columns;
};
