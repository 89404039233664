import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import OrderListPage from '../../../../components/common/OrderListPage';
import arrayToBreadCrumbs from '../../../../utils/sms/arrayToBreadCrumbs';
import { updateSpinnerState } from '../../../../actions/spinner';
import { apiCall } from '../../../../middlewares/api';
import { updateToastInfo } from '../../../../actions/settings';
import { orderStatus, returnOrderStatus } from '../../../../constants/orderStatus';
import ConfirmationModal from '../../../../components/common/confirmationModal';
import { returnOrderColumns } from './utils';
import { apiCallConsts } from '../../../../constants/apiCallConsts';
import { useLocation, useNavigate } from 'react-router-dom';
import ReplacementModal from '../../orders/components/ReplacementModal';
import { isBelongsToSameInvoice } from '../../orders/components/utils';
import { Tab, Tabs } from '@themesberg/react-bootstrap';
import OrderDetails from '../../orders/components/OrderDetails';

const OrderReturnPage = (props) => {
  const {
    type,
    showFilter,
    bulkActionOptions,
    hideBulkActions,
    defaultConfirmationProps,
    confirmationModalProps,
    setConfirmationModalProps,
    uploadButtonProps,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updateMode, setUpdateMode] = useState('');
  const [returnsData, setReturnData] = useState([]);
  const [queryObject, setQueryObject] = useState({});
  const [queryParam, setQueryParam] = useState({});
  const storeName = useSelector(
    (state) => state.user.preferences.storeName || ''
  );
  const userName = useSelector((state) => state?.user?.user?.username || '');
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const breadCrumbItems = arrayToBreadCrumbs([[t('Returns')], [t(type)]]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showReplacementModal, setShowReplacementModal] = useState(false);
  const [replacementType,setReplacementType] = useState("store")
  const navigate = useNavigate();

  const [activeTab,setActiveTab] = useState("Orders List")
  const [tabsToRender,setTabsToRender] = useState([])

  const [tabs, setTabs] = useState([
    { key: 'Orders List', title: 'Order Listing', content: 'orders' },
    ...tabsToRender.map((each) => ({
      key: `Details-${each.objectId}`,
      title: `Details - ${each.orderNo}`,
      content: 'details',
      objectId: each.objectId,
    })),
  ]);
  useEffect(() => {
    setTabs([
      { key: 'Orders List', title: 'Order List', content: 'orders' },
      ...tabsToRender.map((each) => ({
        key: `Details-${each.objectId}`,
        title: `Details - ${each.orderNo}`,
        content: 'details',
        objectId: each.objectId,
      })),
    ])

  },[tabsToRender])
  const handleTabClose = (key) => {
    const updatedTabs = tabsToRender.filter((tab) => key !== `Details-${tab.objectId}`)
    
    setTabsToRender(updatedTabs);

    if (activeTab === key && updatedTabs.length > 0) {
      setActiveTab(updatedTabs[0].key);
    }
  };



  const fetchOrders = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(apiCallConsts.GET_METHOD, `/internal/order`, {
        ...queryParam,
        storeCode: isAdmin ? queryParam?.storeCode || '' : storeName,
        zone: isAdmin
          ? Array.isArray(queryParam?.zone)
            ? queryParam?.zone?.join(',')
            : queryParam?.zone || ''
          : '',
        status: type,
        serviceType: 'reverse',
      });
      setReturnData(res);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t(''),
          message: t('Return Orders fetched successfully'),
        })
      );
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to fetch Orders'),
          message: t(error.message),
        })
      );
    }
  };

  const onRefreshClick = () => {
    fetchOrders();
  };

  const onRowSelect = (selected, objectId) => {
    if (selected) {
      setSelectedOrders((prev) => {
        const selectedItem = returnsData.find(
          (item) => item.objectId === objectId
        );
        return [...prev, selectedItem];
      });
    } else {
      setSelectedOrders((prev) =>
        prev.filter((item) => item.objectId !== objectId)
      );
    }
    setReturnData((prev) =>
      prev.map((item) =>
        item.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const newQueryObject = {};
    queryParams.forEach((value, key) => {
      queryObject[key] = value;
    });

    if (Object.keys(newQueryObject).length > 0) {
      setQueryObject(newQueryObject);
    }
  }, [location.search]);
  useEffect(() => {
    if (Object.keys(queryObject).length > 0) {
      console.log(queryObject, 'this is to type state');
      setQueryParam({ ...queryObject, status: type });
    }
  }, [queryObject, isAdmin]);

  // Fetch orders when queryParam changes
  useEffect(() => {
    const toSearch = Object.values(queryParam).filter(
      (each) => each && each != type
    );
    if (
      !isAdmin ||
      (toSearch?.length > 0 &&
        queryParam &&
        Object.keys(queryParam || {}).length > 0)
    ) {
      fetchOrders();
    } else {
      setReturnData([]);
    }
  }, [queryParam]);

  const updateOrders = async (data) => {
    await apiCall(
      apiCallConsts.PUT_METHOD,
      apiCallConsts.ORDER_UPDATE_URL,
      data
    );
  };

  const handleSelectedRowsUpdate = (actionId) => {
    console.log(selectedOrders);
    if (selectedOrders.length > 1 && actionId === 'qcpass') {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Max 1 order to update'),
          message: t('Please select maximum of 1 order'),
        })
      );
      return;
    }
    if (selectedOrders.length > 1 && actionId === 'refunded') {
      const selectedOrderNo = selectedOrders[0]?.orderNo;
      const isSameOrderitems = selectedOrders.every(
        (order) => order?.orderNo === selectedOrderNo
      );
      if (!isSameOrderitems) {
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Failed!'),
            message: t('Please select same order number items'),
          })
        );
        return;
      }
    }
    const errorDisplayer = () => {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          message: t('Please select correct option for the status'),
        })
      );
    };
    switch (actionId) {
      case 'accept': {
        setUpdateMode(returnOrderStatus.ACCEPTED);
        setConfirmationModalProps(defaultConfirmationProps['qcCheck']);
        handleShowModal();
        break;
      }
      case 'picked': {
        setUpdateMode(returnOrderStatus.PICKED);
        handleShowModal();
        break;
      }
      case 'received': {
        setUpdateMode(returnOrderStatus.RECEIVED);
        handleShowModal();
        break;
      }
      case 'qcpass': {
        setUpdateMode(returnOrderStatus.QCPASSED);
        if (
          selectedOrders[0]?.serialNo &&
          selectedOrders[0]?.serialNo.length > 0
        ) {
          setConfirmationModalProps(defaultConfirmationProps['qcPassed']);
        }
        handleShowModal();
        break;
      }
      case 'refundInitiated': {
        if (selectedOrders[0].isReplacement) {
          errorDisplayer();
          break;
        }
        setUpdateMode(returnOrderStatus.REFUND_INITIATED);
        setConfirmationModalProps(defaultConfirmationProps['refundInitiated']);
        handleShowModal();
        break;
      }
      case 'replace': {
        setUpdateMode();
        setReplacementType("item")
        setShowReplacementModal(true);
        break;
      }
      case 'refunded': {
        setUpdateMode(returnOrderStatus.REFUNDED);
        // setConfirmationModalProps(defaultConfirmationProps['refunded']);
        const firstSelectedorder = selectedOrders[0];
        setConfirmationModalProps([
          {
            type: 'date',
            title: 'Transaction Date',
            value: new Date(),
          },
          {
            type: 'text',
            title: 'Amount',
            value: String(firstSelectedorder?.orderAmount),
          },
          {
            type: 'text',
            title: 'Transaction No.',
            value: '',
          },
          {
            type: 'text',
            title: 'Mode',
            value: firstSelectedorder?.paymentMode,
          },
          {
            type: 'textArea',
            title: 'Remarks',
            value: '',
          },
        ]);
        handleShowModal();
        break;
      }
      case 'replacementInitiated': {
        if (!selectedOrders[0].isReplacement) {
          errorDisplayer();
          break;
        }
        setUpdateMode(returnOrderStatus.REPLACEMENT_INITIATED);
        setShowReplacementModal(true);
        break;
      }
      case 'earlyRefund': {
        setUpdateMode('earlyRefund');
        // setConfirmationModalProps(defaultConfirmationProps['earlyRefund']);
        const firstSelectedorder = selectedOrders[0];
        setConfirmationModalProps([
          {
            type: 'date',
            title: 'Transaction Date',
            value: new Date(),
          },
          {
            type: 'text',
            title: 'Amount',
            value: String(firstSelectedorder?.orderAmount),
          },
          {
            type: 'text',
            title: 'Transaction No.',
            value: '',
          },
          {
            type: 'text',
            title: 'Mode',
            value: firstSelectedorder?.paymentMode,
          },
          {
            type: 'textArea',
            title: 'Remarks',
            value: '',
          },
        ]);
        handleShowModal();
        break;
      }
      default:
        break;
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleOnConfirmClick = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      // console.log(data);
      await updateOrders(data);
      await fetchOrders();
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: ``,
          message: 'Update Successful!',
        })
      );
      setShowModal(false);
      setShowReplacementModal(false);
      setSelectedOrders([]);
      defaultConfirmationProps && setConfirmationModalProps([]);
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Cannot be updated!',
          message: e.message,
        })
      );
    }
  };

  const handleModalOnConfirmClick = async ({ data }) => {

    if(replacementType == "item")
    {
      const selectedOrdersData = {
        id: selectedOrders.map((order) => order.objectId),
        serviceType: returnOrderStatus.SERVICETYPE
      };
      if (data) {
    
        selectedOrdersData.isReplacement = true;
        selectedOrdersData.replacementProduct = data;
      } else {
        selectedOrdersData.remarks = confirmationModalProps[0].value;
      }
      try {
        dispatch(updateSpinnerState(true));
        await apiCall(
          apiCallConsts.PUT_METHOD,
          apiCallConsts.ORDER_UPDATE_URL,
          selectedOrdersData
        );
        await fetchOrders();
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'success',
            title: `Update Successful!`,
            message: '',
          })
        );
        setShowModal(false);
        setShowReplacementModal(false);
        setSelectedOrders([]);
        setConfirmationModalProps([]);
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: 'Cannot be updated!',
            message: error.message,
          })
        );
      }
    }else{
    if (
      selectedOrders[0]?.serialNo?.length > 1 &&
      updateMode === returnOrderStatus.QCPASSED
    ) {
      const result =
        selectedOrders[0]?.serialNo === confirmationModalProps[0]?.value;
      if (!result) {
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('failed to change status'),
            message: t('Please enter correct serialNo!'),
          })
        );
        return;
      }
    }
  

    let selectedOrdersData = {
      id: selectedOrders.map((item) => item.objectId),
      status: updateMode,
      username: userName,
      serviceType: returnOrderStatus.SERVICETYPE,
    };
    const [date, amount, transactionNo, mode, remarks] =
      confirmationModalProps || [];
    switch (updateMode) {
      case returnOrderStatus.ACCEPTED:
        selectedOrdersData.is3PLQcCheckNeeded = confirmationModalProps[0].value;
        break;
      case returnOrderStatus.QCPASSED:
        if (confirmationModalProps?.length > 1) {
          selectedOrdersData.serialNo = confirmationModalProps[0]?.value;
        }
        break;
      case returnOrderStatus.REFUND_INITIATED:
        selectedOrdersData.SRTNo = confirmationModalProps[0].value;
        selectedOrdersData.remarks = confirmationModalProps[1].value;
        break;
      case returnOrderStatus.REPLACEMENT_INITIATED:
        console.log(data);
        selectedOrdersData.storeCode = data.storeCode;
        break;
      case returnOrderStatus.REFUNDED:
        selectedOrdersData.date = date.value;
        selectedOrdersData.amount = amount.value;
        selectedOrdersData.transactionNo = transactionNo.value;
        selectedOrdersData.mode = mode.value;
        selectedOrdersData.remarks = remarks.value;
        break;
      case 'earlyRefund':
        selectedOrdersData.date = date.value;
        selectedOrdersData.amount = amount.value;
        selectedOrdersData.transactionNo = transactionNo.value;
        selectedOrdersData.mode = mode.value;
        selectedOrdersData.remarks = remarks.value;
        selectedOrdersData.isEarlyRefund = true;
        delete selectedOrdersData.status;
      default:
        break;
    }
    handleOnConfirmClick(selectedOrdersData);
  }
  };

  const messageDisplayer = (type, title, message) => {
    dispatch(
      updateToastInfo({
        show: true,
        type: type,
        title: t(title),
        message: t(message),
      })
    );
  };

  const handleBulkUploadSubmit = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      await uploadButtonProps?.handleSubmit(data);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'failed to update orders!', error.message);
      throw new Error(error?.message);
    }
  };

  return (

    <>
    <Tabs
      activeKey={activeTab}
      onSelect={(key) => setActiveTab(key)}
      className="mb-3"
      style={{
        display: 'flex',
        flexWrap: 'nowrap', // Prevent wrapping of tabs
        gap: '10px', // Adds spacing between tabs
        overflowX: 'auto', // Enables horizontal scrolling
        scrollbarWidth: 'thin', // For Firefox, makes the scrollbar thinner
        marginTop:"20px",
        background:"white"
      }}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.key}
          eventKey={tab.key}
          title={
            <span style={{ display: 'flex', alignItems: 'center', width: '200px' }}>
              {tab.title}
              {tab.title !== 'Order List' && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleTabClose(tab.key);
                  }}
                  style={{
                    border: 'none',
                    background: 'none',
                    color: 'red',
                    cursor: 'pointer',
                    marginLeft: '5px',
                  }}
                >
                  ✖
                </button>
              )}
            </span>
          }
          style={{
            flexShrink: 0, // Prevents the tab from shrinking
          }}
        >
          {tab.content === 'orders' && (
              <OrderListPage
              pageType={`${type}orders`}
              showDateFilters={showFilter}
              tableData={returnsData}
              tableColumns={returnOrderColumns(
                onRowSelect,
                navigate,
                bulkActionOptions,
                isAdmin,
                type,setTabsToRender, setActiveTab
              )}
              handleRefresh={onRefreshClick}
              pageTitle={t(`${type} Orders`)}
              breadCrumbItems={breadCrumbItems}
              // pageSubTitle={t(`All the ${type} Orders`)}
              enableBulkActions={selectedOrders.length > 0}
              hideBulkActions={hideBulkActions}
              bulkActionOptions={bulkActionOptions}
              handleItemBulkUpdate={handleSelectedRowsUpdate}
              setQueryParam={setQueryParam}
              uploadButtonProps={uploadButtonProps}
              handleBulkUploadSubmit={handleBulkUploadSubmit}
            />
          )}
          {tab.content === 'details' && <OrderDetails id={tab.objectId} />}
        </Tab>
      ))}
    </Tabs>
    
    <style>
    {`
        /* Add inline styles for scrollbars using CSS */ 
        .mb-3::-webkit-scrollbar {
          height: 6px;
        }
        .mb-3::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 3px;
        }
        .mb-3::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      `}
    </style>
      <ConfirmationModal
        showModal={showModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowModal(false);
          defaultConfirmationProps && setConfirmationModalProps([]);
        }}
        modalBody={t(
          `Are you sure you want to change the status to ${updateMode}?`
        )}
        modalTitle={t(`${updateMode} Order(s)`)}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />
      <ReplacementModal
        showModal={showReplacementModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowReplacementModal(false);
        }}
        selectedOrders={selectedOrders}
        type={replacementType}
        modalBody={t(`Are you sure you want to raise a Replacement Request?`)}
      />
    </>
  );
};

export default OrderReturnPage;
