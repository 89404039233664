import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Breadcrumb } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateSpinnerState } from '../../actions/spinner';
import { setCurrentTripParcels } from '../../actions/trips';
import { convertArrayToObj } from '../../utils/json';
import { getTripBadgeStatus } from '../../utils/trips';
import { returnUserReadableDate } from '../../utils/datetime';
import { orderStatusMap } from '../../utils/constants';
import ExcelDownloadReport from '../../components/common/excel/download';
import { getTripInfoByTemposheetNumber } from '../../parse-functions/trips';
import ActiveTripParcels from '../trips/activeTripParcels';

const CurrentTripDetail = () => {
  const { objectId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tripInfo, setTripInfo] = useState({});
  const [parcels, setParcels] = useState([]);

  useEffect(() => {
    getTripDetails();
  }, []);

  const getTripDetails = async () => {
    dispatch(updateSpinnerState(true));
    try {
      const tripDetails = await getTripInfoByTemposheetNumber(
        '',
        true,
        objectId
      );
      setTripInfo(tripDetails.tripInfo);
      setParcels(tripDetails.parcels);
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t('Trips')}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t('Trip Detail')}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{`${tripInfo?.tripNumber}`}</h4>
          <p className="mb-0">
            <span
              style={{
                marginRight: 12,
              }}
            >
              {tripInfo?.createdBy && (
                <>
                  Created By:{' '}
                  <span className="fw-bold">{tripInfo.createdBy}</span>,
                </>
              )}
            </span>
            <span
              style={{
                marginRight: 12,
              }}
            >
              {tripInfo?.createdAt && (
                <>
                  Created At:{' '}
                  <span className="fw-bold">
                    {returnUserReadableDate(tripInfo.createdAt)}
                  </span>
                  ,
                </>
              )}
            </span>
            <span
              style={{
                marginRight: 12,
              }}
            >
              {tripInfo?.status && (
                <>
                  <span>
                    &nbsp;&nbsp;
                    <Badge
                      bg={getTripBadgeStatus(tripInfo.status)}
                      className="badge-lg"
                    >
                      {tripInfo.status}
                    </Badge>{' '}
                    &nbsp;&nbsp;,
                  </span>
                </>
              )}
            </span>
            <span
              style={{
                marginRight: 12,
              }}
            >
              {tripInfo?.dispatchData?.vehicleDetails?.vehicleType && (
                <>
                  Vehicle Type:{' '}
                  <span className="fw-bold">
                    {tripInfo?.dispatchData?.vehicleDetails?.vehicleType}
                  </span>
                </>
              )}
            </span>
          </p>
        </div>

        <div className="btn-toolbar mb-2 mb-md-0 gap-2">
          <ExcelDownloadReport
            data={parcels}
            fileName={'TripDetails'}
            className="btn-export"
          />
        </div>
      </div>
      <div>
        {tripInfo?.analytics && (
          <span
            style={{
              maxWidth: '100%',
              overflowWrap: 'anywhere',
            }}
          >
            {Object.keys(tripInfo?.analytics)?.map((keyName) => {
              return (
                <>
                  <span
                    style={{
                      marginRight: 10,
                    }}
                  >
                    <span
                      className="fw-bold"
                      style={{
                        marginRight: 5,
                      }}
                    >
                      {orderStatusMap[keyName] || keyName}:
                    </span>
                    <span>{tripInfo?.analytics?.[keyName]},</span>
                  </span>
                </>
              );
            })}
          </span>
        )}
      </div>
      <ActiveTripParcels data={parcels || []} />
    </>
  );
};
export default CurrentTripDetail;
