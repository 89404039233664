export const orderStatus = {
  assigned: 'Assigned',
  accepted: 'Accepted',
  awaitingInvoice: 'Awaiting Invoice',
  invoiced: 'Invoiced',
  readyForPickup: 'Ready For Pickup',
  pickedUp: 'Picked Up',
  rejected: 'Rejected',
  pendingAction: 'Pending Action',
  autoRejected: 'Auto Rejected',
  delivered: 'Delivered',
  cancelled: 'Cancelled',
  preBooked: 'Pre-Booked',
  preRegistered: 'preregister',
  qcfailed: 'QC Failed',
  completed: 'Completed',
  outForDelivery: 'Out For Delivery',
  outOfStock: 'Out Of Stock',
};

export const returnOrderStatus = {
  REQUESETED: 'Requested',
  ACCEPTED: 'Accepted',
  PICKED: 'Picked Up',
  RECEIVED: 'Received',
  QCPASSED: 'QC Passed',
  REFUND_INITIATED: 'Refund Initiated',
  REPLACEMENT_INITIATED: 'Replacement Initiated',
  REFUNDED: 'Refunded',
  SERVICETYPE: 'reverse',
  LOST_IN_TRANSIT : "Lost In-Transit"
};

export const reasonForRejection = [
  'Defective',
  'No Stock',
  'Reserved for Offline',
];

export const cancelableStatus = [
  orderStatus.assigned,
  orderStatus.accepted,
  orderStatus.awaitingInvoice,
];
