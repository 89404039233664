import { faFileExport, faHome, faPlus, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, Button, ButtonGroup, Col, Row } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setActiveTripsListInStore, setOpenTripsListInStore, setSelectedTripInStore } from "../../actions/trips";
import { Routes } from "../../router/routes";
import { getCurrentTripsFromServer, getOpenTripsFromServer } from "../../parse-functions/trips";
import { convertArrayToObj } from "../../utils/json";
import TripsTableData from "./tripsTableData";
import { updateSpinnerState } from "../../actions/spinner";
import { updateToastInfo } from "../../actions/settings";
import DateRangeSelector from "../../components/common/DateRangeSelector";
import { setFiltersInStore } from "../../actions/filters";
import { returnEndOfDay, returnStartOfDay } from "../../utils/datetime";
import { returnActiveTripColumns } from "./tripsUtils";
import { returnActiveTripsDataToDownload } from "../../utils/trips";
import ExcelDownloadReport from "../../components/common/excel/download";

const PendingCloseTrips = () => {
  const { t } = useTranslation();
  const pageType = "activeTrips";
  const dispatch = useDispatch();
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const startDate = queryParams.get("startDate")
  const endDate = queryParams.get("endDate")
  const navigate = useNavigate();
  const isOpen = useSelector((state) => state.trips.openTrips)
  const activeTrips = useSelector((state) => {
    if(isOpen)
    {
    return  state.trips.activeTrips || {}
    }else{
      return {}
    }
  });

  console.log(activeTrips)
  const storeInfo = useSelector((state) => state.user.warehouse || {});
  const filtersValue = useSelector((state) => state.filters?.[pageType]);
  const [dataToDownload, setDataToDownload] = useState([]);
  const isSuperAdmin = useSelector((state) => state.user.preferences.isSuperAdmin || false);

  const handleRowClick = (tripNumber, objectId) => {
    dispatch(setSelectedTripInStore(tripNumber));
    navigate(`/trip/${objectId}?isOpen=true`);
  };

  const handlePrintClick = (event, objectId) => {
    event.stopPropagation();
    window.open(`${window.location.origin}/trips/${objectId}/print`, "_blank")
  }

  const getAllTrips = async (requestProps) => {
    dispatch(updateSpinnerState(true));
    try {
      const tempTrips = await getOpenTripsFromServer({isSuperAdmin,warehouseCode:storeInfo?.warehouseCode,startDate: new Date("2025-01-01"),endDate: endDate ? new Date(endDate) : "" });
      const tripsObject = convertArrayToObj(tempTrips, "tripNumber");
      dispatch(setOpenTripsListInStore(true));
      dispatch(setActiveTripsListInStore(tripsObject));
      const data = returnActiveTripsDataToDownload(tripsObject);
      setDataToDownload(data);
      dispatch(updateToastInfo({
        show: true,
        type: 'success',
        title: t('All trips fetched'),
        message: t('These are all the active trips currently from your warehouse')
      }));
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(updateToastInfo({
        show: true,
        type: 'danger',
        title: t("Error in getting active Trips"),
        message: e.message
      }));
    }
  };

  const handleGetTrips = () => {
    getAllTrips({
      startDate: filtersValue?.startDate ? new Date(filtersValue?.startDate) : returnStartOfDay(new Date()),
      endDate: filtersValue?.endDate ? new Date(filtersValue?.endDate) : returnEndOfDay(new Date()),
      warehouseCode: storeInfo?.warehouseCode,
      isSuperAdmin
    })
  };

  useEffect((each) => {
    try{

      
      if(!Object.keys(activeTrips).length > 0)
      {
        getAllTrips()
      }
    
    }catch(error)
    {
      console.log(error)
    }
  },[])

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>{t("Trips")}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t("List")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("Open Temposheets")}</h4>
          <p className="mb-0">{t("All the trips of your warehouse are listed here.")}</p>
        </div>

        <div className="btn-toolbar mb-2 mb-md-0">
          <Button
            size="sm"
            variant="primary"
            style={{
              marginRight: 20
            }}
            onClick={() => {
              navigate(Routes.PlanningPage.path)
            }}>
            <FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp; {t("New Trip")}
          </Button>
          <ButtonGroup>
            <Button onClick={() => {
              handleGetTrips();
            }} variant="outline-primary" size="sm">
              <FontAwesomeIcon icon={faRefresh} />&nbsp;&nbsp;{t("Refresh")}
            </Button>
            <ExcelDownloadReport
                data={dataToDownload || []}
                fileName={`Dispatched-Trips.csv`}
            />
          </ButtonGroup>
        </div>
      </div>


      <TripsTableData
        columns={returnActiveTripColumns(handlePrintClick)}
        data={Object.values(activeTrips)}
        onRowClick={handleRowClick}
      />
    </>
  )
}
export default PendingCloseTrips;