import { returnUserReadableLocalDate } from '../datetime';

export const exportSchemeDataSummary = (data) => {
  const result = data.map((item) => {
    const obj = {
      Company: item?.company?.join(', '),
      ProductCategory: item?.productCategory?.join(', '),
      SubCategory: item?.subCategory.join(', '),
      SchemeCircularNo: item.schemeCircularNo,
      SchemeCircularDate: returnUserReadableLocalDate(item?.schemeCircularDate),
      ValidFrom: returnUserReadableLocalDate(item?.validFrom),
      ValidTill: returnUserReadableLocalDate(item?.validTill),
      Status: item.status,
      Remakrs: item.remarks,
      SchemeType: item.type[0],
      SchemeValue: item?.schemeSummary?.totalValue,
      SchemeQuantity: item?.schemeSummary?.totalQuantity,
    };
    return obj;
  });
  return result;
};

export const exportSchemeData = (data, type) => {
  let result = [];
  switch (type) {
    case 'Sell-in':
      result = data.map((item) => {
        const obj = {
          PurchaseDate: returnUserReadableLocalDate(item.PurchaseDate),
          PurchaseVNo: item.PurchaseVNo,
          Branch: item.Branch,
          Product: item.Product,
          VanNo: item.VanNo,
          Brand: item.company,
          TotalAmount: formatDecimal(
            item.TotalQuantity < 0 ? -item.TotalAmount : item.TotalAmount
          ),
          TotalQuantity: item.TotalQuantity,
          TotalIGST: formatDecimal(item.TotalIGST),
          TotalCGST: formatDecimal(item.TotalCGST),
          TotalSGST: formatDecimal(item.TotalSGST),
          Unitrate: item.Unitrate,
          TotalGSTAmount: formatDecimal(item.TotalGSTAmount),
          SerialNo: item.SerialNo,
          ReferenceBillNo: item.ReferenceBillNo,
          ReferenceBillDate: returnUserReadableLocalDate(
            item.ReferenceBillDate
          ),
        };
        return obj;
      });
      break;
    case 'Sell-out':
      result = data.map((item) => {
        const obj = {
          OrderNo: item.orderNo,
          OrderDate: item.orderDate?.split('T')[0],
          Branch: item.branch,
          VanNo: item.vanNo,
          Product: item.product,
          Quantity: item.quantity,
          Amount: formatDecimal(item.quantity < 0 ? -item.amount : item.amount),
          CGST: formatDecimal(item.CGST),
          SGST: formatDecimal(item.SGST),
          IGST: formatDecimal(item.IGST),
          GSTAmount: formatDecimal(item.gstAmount),
          InvoiceNumber: item.invoiceNo,
          SerialNumber: item.serialNo,
          ProductCode: item.productCode,
          Brand: item.brand,
          ReturnVoucherNo: item.returnVoucherNo,
          Reason: item.reason,
          TowardsVNo: item.towardsVNo,
          OrderType: item.orderType,
        };
        return obj;
      });
      break;
    default:
      result = data;
      break;
  }
  return result;
};

const formatDecimal = (cell) => {
  return cell.toFixed(2);
};
