import { Button, Form } from '@themesberg/react-bootstrap';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditableDropDown from '../../../components/common/EditableDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRotateLeft,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import AsyncDropdown from '../../../components/AsyncDropdown';
import { debounce } from '../../../utils/debounce';
import { getUnPaidVouchersList } from '../../../parse-functions/payments';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';
import DateRangeSelector from '../../../components/common/DateRangeSelector';

const ApprovedChallanFilters = ({
  openFilter = true,
  onApplyFilter,
  onReset,
}) => {
  const { t } = useTranslation();
  const [isValidInput, setIsValidInput] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [voucherNumber, setVoucherNumber] = useState('');
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [tripNumber, setTripNumber] = useState('');

  const handleReset = () => {
    // setDateRange('');
    setStartDate('');
    setEndDate('');
    setVoucherNumber('');
    setVehicleNumber('');
    setTripNumber('');
    if (onReset) onReset();
  };

  const handleApplyFilter = () => {
    const filters = {
      //   dateRange,
      startDate,
      endDate,
      voucherNumber: voucherNumber?.value,
      vehicleNumber,
      tripNumber,
    };
    if (onApplyFilter) onApplyFilter(filters);
  };

  const fetchOptions = debounce(async (inputValue) => {
    if (inputValue.length > 1) {
      try {
        const res = await getUnPaidVouchersList({
          filter: inputValue.toUpperCase(),
        });
        const options = arrayToDropdownOptions(res);
        return options;
      } catch (error) {
        console.log(error);
      }
    }
  });

  //   const styles = {
  //     container: {
  //       display: 'flex',
  //       flexDirection: 'column',
  //       gap: '10px',
  //       padding: '20px',
  //       border: '1px solid #ccc',
  //       borderRadius: '5px',
  //       maxWidth: '600px',
  //       margin: '0 auto',
  //     },
  //     row: {
  //       display: 'flex',
  //       justifyContent: 'space-between',
  //       alignItems: 'center',
  //       gap: '10px',
  //     },
  //     input: {
  //       width: '100%',
  //       padding: '8px',
  //       border: '1px solid #ccc',
  //       borderRadius: '5px',
  //     },
  //     button: {
  //       padding: '10px 20px',
  //       border: 'none',
  //       borderRadius: '5px',
  //       cursor: 'pointer',
  //     },
  //     applyButton: {
  //       backgroundColor: '#007bff',
  //       color: '#fff',
  //     },
  //     resetButton: {
  //       backgroundColor: '#f8f9fa',
  //       color: '#000',
  //     },
  //     label: {
  //       marginBottom: '5px',
  //       fontWeight: 'bold',
  //     },
  //   };

  return (
    <>
      <div
        className="table-settings mt-2 mb-4"
        style={{
          background: '#EAEDF2',

          maxHeight: openFilter ? '500px' : '0px',
          overflow: openFilter ? '' : 'hidden', // Ensure content is hidden when collapsed
          transition: 'max-height 0.7s ease-in-out', // Smooth transition when toggling
        }}
      >
        {/* <div style={{ gap: 8, display: 'flex' }}> */}
        <Form.Group
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)', // Three columns
            gap: '20px', // Gap between elements
            width: '100%',
            backgroundColor: 'white',
            padding: 20,
          }}
        >
          <div>
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              style={{
                height: 38,
                width: '100%',
              }}
              isInvalid={!isValidInput}
              required
              type="date"
              value={startDate}
              defaultValue={null}
              onChange={(event) => {
                setIsValidInput(true);
                setStartDate(event.target.value);
              }}
              placeholder="Start Date"
            />
          </div>
          <div>
            <Form.Label>End Date</Form.Label>
            <Form.Control
              style={{
                height: 38,
                width: '100%',
              }}
              isInvalid={!isValidInput}
              required
              type="date"
              value={endDate}
              defaultValue={null}
              onChange={(event) => {
                setIsValidInput(true);
                setEndDate(event.target.value);
              }}
              placeholder="End Date"
            />
          </div>
          <div>
            <Form.Label>Trip No.</Form.Label>
            <Form.Control
              style={{
                height: 38,
                width: '100%',
              }}
              isInvalid={!isValidInput}
              required
              type="text"
              value={tripNumber}
              onChange={(event) => {
                setTripNumber(event.target.value);
              }}
              placeholder="Enter the Trip no."
            />
          </div>

          <div>
            <Form.Label>Vehicle No.</Form.Label>
            <Form.Control
              id={'vehicleNo'}
              onChange={(e) => setVehicleNumber(e?.target?.value)}
              style={{
                height: 38,
                width: '100%',
              }}
              value={vehicleNumber}
              //   options={[]}
              placeholder={'Please enter Vehicle Number'}
            />
          </div>
          {/* <div>
            <Form.Label>Date Range</Form.Label>
            <DateRangeSelector
              // onChange={(options) => setChallanNo(options.value)}
              // width={300}
              // disabled={false}
              // fetchOptions={fetchOptions}
              // placeholder={t('search challan/voucher number')}
              showSubmit={false}
              filtersValue={{}}
              onSubmit={(startDate, endDate) => console.log(startDate, endDate)}
              // isMultiSelect={true}
            />
          </div> */}
          <div>
            <Form.Label>Voucher No.</Form.Label>
            <AsyncDropdown
              onChange={(options) => setVoucherNumber(options)}
              width={'100%'}
              disabled={false}
              fetchOptions={fetchOptions}
              placeholder={t('Search challan number')}
              value={voucherNumber}
              backgroundColor={'#FFF'}
              optionColor={'rgba(240, 240, 240, 1)'}
              cacheOptions={false}
              // isMultiSelect={true}
            />
          </div>

          <div
            style={{
              gridColumn: 'span 4', // Span across all columns
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px',
            }}
          >
            <Button
              variant="white"
              className="fa-export"
              onClick={handleReset}
              style={{
                width: '150px',
                height: '38px',
                borderRadius: 0,
                background: '#EAEDF2',
              }}
            >
              <FontAwesomeIcon icon={faArrowRotateLeft} />
              &nbsp;&nbsp;{t('Reset')}
            </Button>

            <Button
              variant="white"
              className="fa-export"
              onClick={handleApplyFilter}
              style={{
                width: '150px',
                height: '38px',
                borderRadius: 0,
                border: '1px solid #262B40',
              }}
            >
              <FontAwesomeIcon icon={faSliders} />
              &nbsp;&nbsp;{t('Apply Filter')}
            </Button>
          </div>
        </Form.Group>
        {/* </div> */}
      </div>

      {/* {!openFilter &&
        (orderNo != '' ||
          zone != '' ||
          vanNo != '' ||
          storeCode != '' ||
          startDate != '' ||
          endDate != '') && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              className="gap-2"
              style={{
                display: 'flex',

                width: '100%',
                background: '#F5F8FB',

                padding: 20,
              }}
            >
              {filterObj.map((each) => {
                if (each.value != '') {
                  return (
                    <div>
                      <Form.Label style={{ color: '#262B4099' }}>
                        {each.label}
                      </Form.Label>
                      <Badge
                        variant="light"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '188px',
                          height: '41px',
                          background: 'white',
                          borderRadius: '2px',
                          cursor: 'pointer',
                          color: '#262B40',
                          fontSize: '14px',
                          fontWeight: '400',
                          lineHeight: '16.41px',
                        }}
                      >
                        {each.value}
                        <span
                          onClick={() => {
                            handleEmpty(each.func);
                          }}
                          style={{
                            color: '#262B40',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '16.41px',
                          }}
                        >
                          &times;
                        </span>
                      </Badge>
                    </div>
                  );
                }
              })}
            </div>
            <Button
              variant="primary"
              className="fa-export"
              onClick={handleReset}
              style={{
                width: '150px',
                height: '38px',
                borderRadius: 0,
              }}
            >
              <FontAwesomeIcon icon={faBroom} />
              &nbsp;&nbsp;{t('Clear')}
            </Button>
          </div>
        )} */}
    </>
  );
};

export default ApprovedChallanFilters;
