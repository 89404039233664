import React, { useEffect, useState } from 'react';
import { getColumns, orderCancelColumns } from '../utils';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal } from '@themesberg/react-bootstrap';
import { generateRandomId } from '../../../../utils/string';
import TableOnly from '../../../scheme-management/Components/IndividualScheme/TableOnly';
import { orderStatus } from '../../../../constants/orderStatus';
import { useDispatch } from 'react-redux';
import { updateToastInfo } from '../../../../actions/settings';
import EditableDropDown from '../../../../components/common/EditableDropdown';

const CancellationModal = (props) => {
  const { data, showModal, closeModal, onConfirm } = props;
  const [ordersData, setOrdersData] = useState();
  const { t } = useTranslation();
  const [formErrors, setFormErrors] = useState({
    srtNumber: '',
    invoiceNumber: '',
    reason : '',
    remarks : ''
  });
  const [formInput, setFormInput] = useState({
    srtNumber: '',
    invoiceNumber: '',
    reason : '',
    remarks : ''
  });
  const dispatch = useDispatch();

  const handleChange = (row, value) => {
    setOrdersData((prev) =>
      prev.map((item) =>
        item.objectId === row.objectId ? { ...item, status: value } : item
      )
    );
  };

  const [statusArray, setStatusArray] = useState([
    { label: 'Book by Mistake', value: 'Book by Mistake' },
    { label: 'Buyback Issue', value: 'Buyback Issue' },
    { label: 'Cashback Issue', value: 'Cashback Issue' },
    { label: 'Dealer Order', value: 'Dealer Order' },
    { label: 'Defective Unit', value: 'Defective Unit' },
    { label: 'Delayed Delivery', value: 'Delayed Delivery' },
    { label: 'Demo Unit', value: 'Demo Unit' },
    { label: 'Duplicate Order', value: 'Duplicate Order' },
    { label: 'Finance Issue', value: 'Finance Issue' },
    { label: 'Fraud Orders', value: 'Fraud Orders' },
    { label: 'GST Issue', value: 'GST Issue' },
    { label: 'Guest Refused to Accept Delivery', value: 'Guest Refused to Accept Delivery' },
    { label: 'Installation Issue', value: 'Installation Issue' },
    { label: 'Listing Issue', value: 'Listing Issue' },
    { label: 'Remark Mandatory', value: 'Remark Mandatory' },
    { label: 'Lost to Competitor', value: 'Lost to Competitor' },
    { label: 'Loyalty Points Issue', value: 'Loyalty Points Issue' },
    { label: 'No Stock', value: 'No Stock' },
    { label: 'Non Serviceable', value: 'Non Serviceable' },
    { label: 'Not Required', value: 'Not Required' },
    { label: 'Open Box', value: 'Open Box' },
    { label: 'Others', value: 'Others' },
    { label: 'Out of Station', value: 'Out of Station' },
    { label: 'Payment Gateway Issue', value: 'Payment Gateway Issue' },
    { label: 'Purchased from VS Store', value: 'Purchased from VS Store' },
    { label: 'Wrong Coupon Applied', value: 'Wrong Coupon Applied' },
    { label: 'Wrong Model Delivered', value: 'Wrong Model Delivered' },
    { label: 'Wrong Model Purchased', value: 'Wrong Model Purchased' }
]);



  const validate = () => {
    if (
      ordersData?.length > 1 &&
      ordersData?.filter((item) => item.status === orderStatus.cancelled)
        .length === 0
    ) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          message: t('No items selected for cancellation.'),
        })
      );
      return;
    }
    let isValid = true;
    if (formInput.srtNumber.length === 0) {
      setFormErrors((prev) => ({
        ...prev,
        srtNumber: 'Cannot be empty',
      }));
      isValid = false;
    }
    if (formInput.remarks.length === 0) {
      setFormErrors((prev) => ({
        ...prev,
        remarks: 'Cannot be empty',
      }));
      isValid = false;
    }
    if (formInput.reason.length === 0) {
      setFormErrors((prev) => ({
        ...prev,
        reason: 'Cannot be empty',
      }));
      isValid = false;
    }
    if (
      ordersData.length > 1 &&
      ordersData?.some(
        (item) =>
          item.status === orderStatus.invoiced ||
          item.status === orderStatus.readyForPickup
      )
    ) {
      if (formInput.invoiceNumber.length === 0) {
        setFormErrors((prev) => ({
          ...prev,
          invoiceNumber: 'Cannot be empty',
        }));
        isValid = false;
      }
    } else {
      setFormInput((prev) => ({
        ...prev,
        invoiceNumber: '',
      }));
    }
    return isValid;
  };

  const handleConfirm = () => {
    const isValid = validate();
    console.log(isValid);
    if (!isValid) {
      return;
    } else {
      onConfirm(ordersData, formInput);
      setFormInput({
        srtNumber: '',
        invoiceNumber: '',
        reason : '',
        remarks : ''
      })
    }
  };

  const handleTextChange = (key, e) => {
    const { value } = e.target;
    setFormInput((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    setOrdersData(data);
  }, [data]);
  return (
    <Modal
      id={generateRandomId()}
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={() => {
        closeModal();
        setFormInput({});
        setFormErrors({});
      }}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title className="h6">
          {t(`Items Cancellation Modal`)}
        </Modal.Title>
        <Button
          variant="close"
          aria-label="Close"
          onClick={() => {
            closeModal();
            setFormInput({});
            setFormErrors({});
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <TableOnly
          id="CancellationModal"
          isFooterRequired={false}
          data={ordersData}
          columns={orderCancelColumns(handleChange, data)}
        />
        <Form.Group>
          <Form.Label>SRT Number</Form.Label>
          <Form.Control
            type={'text'}
            id={generateRandomId()}
            name={'SRT Number'}
            value={formInput.srtNumber}
            onChange={(e) => handleTextChange('srtNumber', e)}
            isInvalid={formErrors['srtNumber']}
          />
          <Form.Control.Feedback type="invalid">
            {t(formErrors['srtNumber'])}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Reason</Form.Label>
          <EditableDropDown
                                id={'Reason'}
                                onChange={(e) =>{

                                  console.log(e)
                                  setFormInput((prev) => {
                                    return {...prev,reason:e.value}
                                  })
                                }}
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                
                                value={formInput?.reason}
                                options={statusArray}
                                placeholder={'Choose the Reason'}
                              />
          <Form.Control.Feedback type="invalid">
            {t(formErrors['reasons'])}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Remarks</Form.Label>
          <Form.Control
            type={'text'}
            id={generateRandomId()}
            name={'Remarks'}
            value={formInput.remarks}
            onChange={(e) => handleTextChange('remarks', e)}
            isInvalid={formErrors['remarks']}
          />
          <Form.Control.Feedback type="invalid">
            {t(formErrors['remarks'])}
          </Form.Control.Feedback>
        </Form.Group>
        {ordersData?.length > 1 &&
          ordersData?.some(
            (item) =>
              item.status === orderStatus.invoiced ||
              item.status === orderStatus.readyForPickup
          ) && (
            <Form.Group>
              <Form.Label>New Invoice</Form.Label>
              <Form.Control
                type={'text'}
                id={generateRandomId()}
                name={'Invoice Number'}
                value={formInput.invoiceNumber}
                onChange={(e) => handleTextChange('invoiceNumber', e)}
                isInvalid={formErrors['invoiceNumber']}
              />
              <Form.Control.Feedback type="invalid">
                {t(formErrors['invoiceNumber'])}
              </Form.Control.Feedback>
            </Form.Group>
          )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleConfirm}>
          {t('Confirm')}
        </Button>
        <Button
          variant="link"
          className="text-gray ms-auto"
          onClick={closeModal}
        >
          {t('Cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancellationModal;
