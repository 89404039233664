import React, { useState, useEffect } from 'react';
import { Modal } from '@themesberg/react-bootstrap';
import NewTable from '../../components/common/NewTable';
import { tripsViewColumns } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { getTripInfoByObjectId } from '../../parse-functions/trips';
import { updateSpinnerState } from '../../actions/spinner';
import { updateToastInfo } from '../../actions/settings';
import TripSaveModal from './TripSaveModal';
import { useTranslation } from 'react-i18next';
import TripEditModal from './TripEditModal';

const TripViewModal = ({ show, handleClose, tripsData, handleSaveClick }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedTrip, setSelectedTrip] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [saveData, setSaveData] = useState({});
  const vendorsList = useSelector((state) => state?.vendors?.vendorsList || {});
  const [showSaveModal, setShowSaveModal] = useState(false);

  const handleEditClick = async (tripId) => {
    if (!tripsData.find((item) => item.objectId === tripId).perDelivery) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          message: `${t('Selected trip is not Per Delivery!')}`,
        })
      );
      return;
    }
    try {
      dispatch(updateSpinnerState(true));
      const tripDetails = await getTripInfoByObjectId(tripId, true);
      setSelectedTrip({
        trip: tripDetails.tripInfo,
        parcels: tripDetails.parcels,
      });
      setShowEditModal(true);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to fetch data!'),
          message: `${error.message}`,
        })
      );
    }
  };

  const handleSubmitClick = (data) => {
    setSaveData(data);
    setShowSaveModal(true);
  };

  const onTripViewClick = (tripId) => {
    window.open(
      `${window.location.origin}/tripdetails/${tripId}`,
      '_blank'
    );
  };
  return (
    <>
      <Modal show={show} centered onHide={handleClose} size="xl">
        <Modal.Header>
          <Modal.Title>
            <h5>Total Trips: {tripsData?.length}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewTable
            id={'viewTrips'}
            data={tripsData}
            columns={tripsViewColumns({
              handleEditClick,
              handleSaveClick,
              onTripViewClick,
            })}
          />
        </Modal.Body>
      </Modal>
      {selectedTrip && (
        <TripEditModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          tripDetails={selectedTrip}
          onSubmit={handleSubmitClick}
          handleSaveClick={handleSaveClick}
        />
      )}
      {Object.keys(saveData).length > 0 && (
        <TripSaveModal
          show={showSaveModal}
          handleClose={() => setShowSaveModal(false)}
          saveData={saveData}
          setSaveData={setSaveData}
          tripDetails={selectedTrip}
          onSubmit={handleSaveClick}
          vendorsList={vendorsList}
          setShowEditModal={setShowEditModal}
        />
      )}
    </>
  );
};

export default TripViewModal;
