import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCancel, faCheckDouble, faChevronRight, faEye, faPen, faUndo } from '@fortawesome/free-solid-svg-icons';
import { returnProductInfo } from '../../../utils/sms/tableUtils';
import { getTableRowCheckBox } from '../../../utils/table';
import CountdownTimer from './components/CountdownTimer';
import {
  Badge,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from '@themesberg/react-bootstrap';
import { getTripBadgeStatus } from '../../../utils/trips';
import {
  returnUserReadableDate,
  returnUserReadableDateTime,
} from '../../../utils/datetime';
import { orderStatus } from '../../../constants/orderStatus';
import EditableDropDown from '../../../components/common/EditableDropdown';
import { generateRandomId } from '../../../utils/string';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';
import { ErrorMessage } from '../../scheme-management/Components/StyledComponents';
import ActionButtonWithIcons from '../../../components/common/ActionButtonWithIcons';
import ViewModal from '../../../components/common/ViewModal';

export const orderTableColumns = (
  onRowSelect,
  updateIds,
  bulkActionOptions,
  isAdmin,
  isCustomerPickup = false,setTabsToRender, setActiveTab
) => {
  const columns = [
    {
      dataField: 'productTitle',
      text: 'Product',
      formatter: (cell, row) => {
        return returnProductInfo([cell]);
      },
    },
    {
      dataField: 'orderNo',
      text: 'order No',
      sort: true,
    },
    {
      dataField: 'orderDateandTime',
      text: 'placed On',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            {returnUserReadableDateTime(cell?.iso) || ''}
          </div>
        );
      },
    },
    {
      dataField: 'customerName',
      text: 'Customer Name',
      sort: true,
    },
    {
      dataField: 'vanNo',
      text: 'van No',
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
    },
    {
      dataField: 'paymentGateway',
      text: 'payment Details',
      formatter: (cell, row) => {
        return (
          <div>
            <div className="d-block">{row?.paymentGateway || ''}</div>
            <div className="d-block">{row?.paymentType || ''}</div>
          </div>
        );
      },
    },
    {
      dataField: 'shippingState',
      text: 'Shipping State',
    },
    {
      dataField: 'updatedAt',
      text: 'last update',
      sort: true,
      formatter: (cell, row) => {
        return returnUserReadableDateTime(cell);
      },
    },
    {
      dataField: 'orderType',
      text: 'orderType',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">
              <span>
                &nbsp;&nbsp;
                <Badge bg={getTripBadgeStatus(cell)} className="badge-lg">
                  {row?.isIBT ?  "Branch Transfers" : row?.isHyperLocal
                    ? 'Hyper Local'
                    : row?.isCustomerPickup
                    ? 'Store Pickup'
                    : row?.isLarge || row?.isMedium
                    ? 'DMS Delivery'
                    : 'Courier'}
                </Badge>
                &nbsp;&nbsp;
              </span>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'view',
      text: 'View',
      searchable: false,
      formatter: (cell, row) =>       <FontAwesomeIcon
      icon={faArrowRight}
      style={{ cursor: 'pointer' }}
      onClick={(e) => {

        
        setActiveTab(`Details-${row.objectId}`);
        setTabsToRender(prev => {

          const exist = prev.filter((each) => each.objectId == row.objectId)

          if(exist.length > 0)
          {
            return prev
          }
          return [row,...prev]
        }
        )
      }}
    /> , 
    },
  ];
  if (bulkActionOptions) {
    columns.splice(0, 0, {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        const isInvoicedLarge =
          bulkActionOptions[0].value === 'invoiced' && row?.isLarge;

        return !isAdmin && row?.isLarge ? (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id={`tooltip-${row?.objectId}`}>
                Large Products are only allowed to update in DMS
              </Tooltip>
            }
          >
            <div>
              {getTableRowCheckBox(onRowSelect, cell, row?.isSelected, true)}
            </div>
          </OverlayTrigger>
        ) : (
          <div>
            {getTableRowCheckBox(
              onRowSelect,
              cell,
              row?.isSelected,
              false // Enable checkbox when not invoiced or not large
            )}
          </div>
        );
      },
    });
  }

  if (isAdmin) {
    columns.splice(2, 0, {
      dataField: 'storeCode',
      text: 'Store Name',
    });
  }

  if (isCustomerPickup) {
    columns.splice(5, 0, {
      dataField: 'expectedDeliveryDate',
      text: 'Expected PickUp',
      formatter: (cell, row) => {
        let date = new Date(new Date(cell).getTime() - 5.5 * 60 * 60 * 1000);
        return returnUserReadableDateTime(date);
      },
    });
  }
  return columns;
};

export const orderCancelColumns = (handleChange, data) => {
  const columns = [
    {
      dataField: 'productTitle',
      text: 'Product',
      formatter: (cell, row) => {
        return returnProductInfo([cell]);
      },
    },
    {
      dataField: 'orderNo',
      text: 'order No',
      sort: true,
    },
    {
      dataField: 'orderDateandTime',
      text: 'placed On',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            {returnUserReadableDateTime(cell?.iso) || ''}
          </div>
        );
      },
    },
    {
      dataField: 'customerName',
      text: 'Customer Name',
      sort: true,
    },
    {
      dataField: 'vanNo',
      text: 'van No',
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => {
        const currentItem = data.find(
          (item) => item.objectId === row?.objectId
        );
        const options = arrayToDropdownOptions([
          orderStatus.cancelled,
          currentItem?.status,
        ]);

        return data.length > 1 ? (
          <Form.Group>
            <EditableDropDown
              id={generateRandomId()}
              onChange={(e) => handleChange(row, e.value)}
              value={cell}
              options={options}
              required={true}
              placeholder={'Select a status'}
            />
            {row?.error && <ErrorMessage>{row?.error}</ErrorMessage>}
          </Form.Group>
        ) : (
          <>{orderStatus.cancelled}</>
        );
      },
    },
  ];
  return columns;
};

export const orderAssignedColumns = (
  onRowSelect,
  updateIds,
  handleTimeOut,
  bulkActionOptions,
  isAdmin,setTabsToRender, setActiveTab
) => {

  
  const columns = [
    {
      dataField: 'productTitle',
      text: 'Product',
      formatter: (cell, row) => {
        return returnProductInfo([cell]);
      },
    },
    {
      dataField: 'orderNo',
      text: 'order No',
      sort: true,
    },
    {
      dataField: 'orderDateandTime',
      text: 'placed On',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            {returnUserReadableDateTime(cell?.iso) || ''}
          </div>
        );
      },
    },
    {
      dataField: 'customerName',
      text: 'Customer Name',
      sort: true,
    },
    {
      dataField: 'vanNo',
      text: 'van No',
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
    },
    {
      dataField: 'paymentGateway',
      text: 'payment Details',
      formatter: (cell, row) => {
        return (
          <div>
            <div className="d-block">{row?.paymentGateway || ''}</div>
            <div className="d-block">{row?.paymentType || ''}</div>
          </div>
        );
      },
    },
    {
      dataField: 'expectedDeliveryDate',
      text: 'Expected Delivery',
      formatter: (cell, row) => {
        let date = new Date(new Date(cell).getTime() - 5.5 * 60 * 60 * 1000);
        return returnUserReadableDateTime(date);
      },
    },
    {
      dataField: 'expiresAt',
      text: 'TimeRemaining',
      searchable: false,
      formatter: (cell, row) => {
        let time = remainingTime(cell?.iso);
        return (
          <span className=" fs-6 text-danger">
            {row?.isLarge || row?.isMedium || row?.isEndlessAisle ? (
              '-'
            ) : (
              <CountdownTimer
                hours={time?.hours < 0 ? 0 : time.hours}
                mins={time?.mins < 0 ? 0 : time.mins}
                callback={() => handleTimeOut(row?.objectId)}
              />
            )}
          </span>
        );
      },
    },
    {
      dataField: 'orderType',
      text: 'orderType',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">
              <span>
                &nbsp;&nbsp;
                <Badge bg={getTripBadgeStatus(cell)} className="badge-lg">
                  {row?.isIBT ?  "Branch Transfers" : row?.isHyperLocal
                    ? 'Hyper Local'
                    : row?.isCustomerPickup
                    ? 'Store Pickup'
                    : row?.isLarge || row?.isMedium
                    ? 'DMS Delivery'
                    : 'Courier'}
                </Badge>
                &nbsp;&nbsp;
              </span>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'view',
      text: 'View',
      searchable: false,
      formatter: (cell, row) =>       <FontAwesomeIcon
      icon={faArrowRight}
      style={{ cursor: 'pointer' }}
      onClick={(e) => {

        
        setActiveTab(`Details-${row.objectId}`);
        setTabsToRender(prev => {

          const exist = prev.filter((each) => each.objectId == row.objectId)

          if(exist.length > 0)
          {
            return prev
          }
          return [row,...prev]
        }
        )
      }}
    /> , 
    },
  ];
  if (bulkActionOptions) {
    columns.splice(0, 0, {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    });
  }
  if (isAdmin) {
    columns.splice(2, 0, {
      dataField: 'storeCode',
      text: 'Store Name',
    });
  }
  return columns;
};

export const orderAcceptedColumns = (
  onRowSelect,
  updateIds,
  handleTimeOut,
  bulkActionOptions,
  isAdmin,setTabsToRender, setActiveTab
) => {
  const columns = [
    {
      dataField: 'productTitle',
      text: 'Product',
      formatter: (cell, row) => {
        return returnProductInfo([cell]);
      },
    },
    {
      dataField: 'orderNo',
      text: 'order No',
      sort: true,
    },
    {
      dataField: 'orderDateandTime',
      text: 'placed On',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            {returnUserReadableDateTime(cell?.iso) || ''}
          </div>
        );
      },
    },
    {
      dataField: 'customerName',
      text: 'Customer Name',
      sort: true,
    },
    {
      dataField: 'vanNo',
      text: 'van No',
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
    },
    {
      dataField: 'updatedAt',
      text: 'last update',
      sort: true,
      formatter: (cell, row) => {
        return returnUserReadableDateTime(cell);
      },
    },
    {
      dataField: 'paymentGateway',
      text: 'payment Details',
      formatter: (cell, row) => {
        return (
          <div>
            <div className="d-block">{row?.paymentGateway || ''}</div>
            <div className="d-block">{row?.paymentType || ''}</div>
          </div>
        );
      },
    },
    {
      dataField: 'expectedDeliveryDate',
      text: 'Expected Delivery',
      formatter: (cell, row) => {
        let date = new Date(new Date(cell).getTime() - 5.5 * 60 * 60 * 1000);
        return returnUserReadableDateTime(date);
      },
    },
    {
      dataField: 'expiresAt',
      text: 'TimeRemaining',
      formatter: (cell, row) => {
        // let result = addTwoHoursOrNextDay(new Date(row?.updatedAt));
        // let time = remainingTime(result);
        let time = remainingTime(cell?.iso);
        return (
          <span className=" fs-6 text-danger">
            {row?.isLarge || row?.isMedium || row?.isEndlessAisle ? (
              '-'
            ) : (
              <CountdownTimer
                hours={time?.hours < 0 ? 0 : time.hours}
                mins={time?.mins < 0 ? 0 : time.mins}
                callback={() => handleTimeOut(row?.objectId)}
              />
            )}
          </span>
        );
      },
    },
    {
      dataField: 'orderType',
      text: 'orderType',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">
              <span>
                &nbsp;&nbsp;
                <Badge bg={getTripBadgeStatus(cell)} className="badge-lg">
                  {row?.isIBT ?  "Branch Transfers" : row?.isHyperLocal
                    ? 'Hyper Local'
                    : row?.isCustomerPickup
                    ? 'Store Pickup'
                    : row?.isLarge || row?.isMedium
                    ? 'DMS Delivery'
                    : 'Courier'}
                </Badge>
                &nbsp;&nbsp;
              </span>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'view',
      text: 'View',
      searchable: false,
      formatter: (cell, row) =>       <FontAwesomeIcon
      icon={faArrowRight}
      style={{ cursor: 'pointer' }}
      onClick={(e) => {

        
        setActiveTab(`Details-${row.objectId}`);
        setTabsToRender(prev => {

          const exist = prev.filter((each) => each.objectId == row.objectId)

          if(exist.length > 0)
          {
            return prev
          }
          return [row,...prev]
        }
        )
      }}
    /> , 
    },
  ];
  if (bulkActionOptions) {
    columns.splice(0, 0, {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return !isAdmin && row?.isLarge ? (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id={`tooltip-${row?.objectId}`}>
                Large Products are only allowed to update in DMS
              </Tooltip>
            }
          >
            <div>
              {getTableRowCheckBox(onRowSelect, cell, row?.isSelected, true)}
            </div>
          </OverlayTrigger>
        ) : (
          <div>
            {getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false)}
          </div>
        );
      },
    });
  }
  if (isAdmin) {
    columns.splice(2, 0, {
      dataField: 'storeCode',
      text: 'Store Name',
    });
  }
  return columns;
};

export const remainingTime = (expiringTime) => {
  const currentTime = new Date();
  const expiryTime = new Date(expiringTime);
  const diffTime = expiryTime - currentTime;
  const hours = Math.floor(diffTime / (1000 * 60 * 60));
  const mins = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
  return {
    hours,
    mins,
  };
};

export const preRegisteredColumns = () => {
  const columns = [
    {
      dataField: 'vanNo',
      text: 'vanNo',
    },
    {
      dataField: 'name',
      text: 'customerName',
    },
    {
      dataField: 'email',
      text: 'email',
    },
    {
      dataField: 'mobileNumber',
      text: 'Number',
    },
    {
      dataField: 'orderDate',
      text: 'order date',
    },
  ];
  return columns;
};

export const preBookedColumns = (navigate) => {
  const columns = [
    {
      dataField: 'productTitle',
      text: 'Product',
      formatter: (cell, row) => {
        return returnProductInfo([cell]);
      },
    },
    {
      dataField: 'vanNo',
      text: 'vanNo',
    },
    {
      dataField: 'mrp',
      text: 'MRP',
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
    },
    {
      dataField: 'view',
      text: 'View',
      formatter: (cell, row) => <ViewModal rowId={row?.objectId} />, 

    },
  ];
  return columns;
};

export const sameorderItemsColumns = (isAdmin) => {
  const columns = [
    {
      dataField: 'productTitle',
      text: 'Product',
      formatter: (cell, row) => {
        return returnProductInfo([cell]);
      },
    },
    {
      dataField: 'orderNo',
      text: 'order No',
    },
    {
      dataField: 'customerName',
      text: 'Customer Name',
      sort: true,
    },
    {
      dataField: 'vanNo',
      text: 'vanNo',
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">
              <span>
                &nbsp;&nbsp;
                <Badge bg={getTripBadgeStatus(cell)} className="badge-lg">
                  {cell?.toUpperCase()}
                </Badge>
                &nbsp;&nbsp;
              </span>
            </div>
          </div>
        );
      },
    },
  ];
  if (isAdmin) {
    columns.push({
      dataField: 'storeCode',
      text: 'Store Name',
    });
  }
  return columns;
};

export const getColumns = (
  type,
  onRowSelect,
  navigate,
  handleTimeOut,
  bulkActionOptions,
  isAdmin,setTabsToRender, setActiveTab
) => {

 
  switch (type) {
    case orderStatus.assigned: {
      return orderAssignedColumns(
        onRowSelect,
        navigate,
        handleTimeOut,
        bulkActionOptions,
        isAdmin,setTabsToRender, setActiveTab
      );
    }
    case orderStatus.preRegistered: {
      return preRegisteredColumns();
    }
    case orderStatus.preBooked: {
      return preBookedColumns(navigate);
    }
    case orderStatus.accepted: {
      return orderAcceptedColumns(
        onRowSelect,
        navigate,
        handleTimeOut,
        bulkActionOptions,
        isAdmin,setTabsToRender, setActiveTab
      );
    }
    default: {
      return orderTableColumns(
        onRowSelect,
        navigate,
        bulkActionOptions,
        isAdmin,false,setTabsToRender, setActiveTab
      );
    }
  }
};

export const searchByFields = [
  {
    label: 'Search by Phone Number',
    value: 'contactNo',
  },
  {
    label: 'Search by Invoice No',
    value: 'invoiceNo',
  },
  {
    label: 'Search by Serial No',
    value: 'serialNo',
  },
  {
    label: 'Search by Order No',
    value: 'orderNo',
  },
  {
    label: 'Search by Customer Name',
    value: 'customerName',
  },
  // {
  //   label: 'Search by Product Code',
  //   value: 'productCode',
  // },
  // {
  //   label: 'Search by Product Name',
  //   value: 'productName',
  // },
  // {
  //   label: 'Search by Product Category',
  //   value: 'productCategory',
  // },
];

export const ordersDataColumns = (navigate, setTabsToRender, setActiveTab) => {
  const columns = [
    {
      dataField: 'orderDateTime',
      text: 'Order Date',
      sort: true,
      formatter: (cell, row) => {
        let date = new Date(new Date(cell).getTime() + 5.5 * 60 * 60 * 1000);
        return returnUserReadableDateTime(date);
      },
    },
    {
      dataField: 'orderNo',
      text: 'orderNo',
    },
    {
      dataField: 'invoiceNo',
      text: 'Invoice No',
      formatter: (cell, row) => {
        return <div>{cell || '-'}</div>;
      },
    },
    {
      dataField: 'itemLineNo',
      text: 'item Line No',
    },
    {
      dataField: 'storeCode',
      text: 'store',
      formatter: (cell, row) => {
        return <div className="fw-bold">{cell ? cell : '-'}</div>;
      },
    },
    {
      dataField: 'customerName',
      text: 'Customer Name',
    },
    {
      dataField: 'shippingState',
      text: 'Shipping State',
    },
    {
      dataField: 'mobileNumber',
      text: 'Mobile Number',
    },
    {
      dataField: 'vanNo',
      text: 'vanNo',
    },
    {
      dataField: 'productTitle',
      text: 'Product',
      formatter: (cell, row) => {
        return returnProductInfo([cell]);
      },
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
    },
    {
      dataField : "awbNumber",
      text : "Awb Number",
    },
    {
      dataField: 'paymentGateway',
      text: 'payment Details',
      formatter: (cell, row) => {
        return (
          <div>
            <div className="d-block">{row?.paymentGateway || ''}</div>
            <div className="d-block">{row?.paymentType || ''}</div>
          </div>
        );
      },
    },
    {
      dataField: 'expiresAt',
      text: 'TimeRemaining',
      formatter: (cell, row) => {
        let time = remainingTime(cell?.iso);
        return (
          <span className=" fs-6 text-danger">
            {row?.isLarge ||
            row?.isMedium ||
            row?.serviceType == 'reverse' ||
            (row?.status != 'Assigned' && row?.status != 'Accepted') ? (
              '-'
            ) : (
              <CountdownTimer
                hours={time?.hours < 0 ? 0 : time.hours}
                mins={time?.mins < 0 ? 0 : time.mins}
                callback={() => {}}
              />
            )}
          </span>
        );
      },
    },
    {
      dataField: 'status',
      text: 'Internalstatus',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">
              <span>
                &nbsp;&nbsp;
                <Badge bg={getTripBadgeStatus(cell)} className="badge-lg">
                  {cell?.toUpperCase()}
                </Badge>
                &nbsp;&nbsp;
              </span>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'orderStatus',
      text: 'CustomerStatus',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">
              <span>
                &nbsp;&nbsp;
                <Badge bg={getTripBadgeStatus(cell)} className="badge-lg">
                  {cell?.toUpperCase()}
                </Badge>
                &nbsp;&nbsp;
              </span>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'courierStatus',
      text: 'Courier Status',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">
              {cell ? (
                <span>
                  &nbsp;&nbsp;
                  <Badge bg={getTripBadgeStatus(cell)} className="badge-lg">
                    {cell?.toUpperCase()}
                  </Badge>
                  &nbsp;&nbsp;
                </span>
              ) : (
                '-'
              )}
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'orderType',
      text: 'orderType',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">
              <span>
                &nbsp;&nbsp;
                <Badge bg={getTripBadgeStatus(cell)} className="badge-lg">
                 {cell}
                </Badge>
                &nbsp;&nbsp;
              </span>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'expectedDeliveryDate',
      text: 'Expected Delivery',
      formatter: (cell, row) => {
        return returnUserReadableDate(cell);
      },
    },
    {
      dataField: 'view',
      text: 'View',
      searchable: false,
      formatter: (cell, row) =>       <FontAwesomeIcon
      icon={faArrowRight}
      style={{ cursor: 'pointer' }}
      onClick={(e) => {

        
        setActiveTab(`Details-${row.objectId}`);
        setTabsToRender(prev => {

          const exist = prev.filter((each) => each.objectId == row.objectId)

          if(exist.length > 0)
          {
            return prev
          }
          return [row,...prev]
        }
        )
      }}
    /> , 
    },
  ];
  return columns;
};

export const manualAssignColumns = (onRowSelect, navigate) => {
  const columns = [
    {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    },
    {
      dataField: 'storeCode',
      text: 'Store Name',
    },
    {
      dataField: 'vanNo',
      text: 'Van No',
      formatter: (cell, row) => {
        return returnProductInfo(cell);
      },
    },
    {
      dataField: 'orderNo',
      text: 'order No',
      sort: true,
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
      formatter: (cell, row) => {
        return returnProductInfo(cell);
      },
    },
    {
      dataField: 'updatedAt',
      text: 'Last Update',
      sort: true,
      formatter: (cell, row) => {
        return returnUserReadableDateTime(cell);
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => {
        return (
          <Badge bg={getTripBadgeStatus(cell)} className="badge-lg">
            {cell}
          </Badge>
        );
      },
    },
    {
      dataField: '',
      text: 'Driver Details',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">{row?.driverName}</div>
            <div className="fw-bold">{row?.contactNumber}</div>
            <div className="fw-bold">{row?.vehicleNumber}</div>
          </div>
        );
      },
    },
    {
      dataField: 'view',
      text: 'View',
      formatter: (cell, row) => <ViewModal rowId={row?.objectId} />, 

    },
  ];
  return columns;
};

export const HyperLocalDeliveryColumns = (onRowSelect, navigate) => {
  const columns = [
    {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    },
    {
      dataField: 'storeCode',
      text: 'Store Name',
    },
    {
      dataField: 'orderNo',
      text: 'order No',
      sort: true,
    },
    {
      dataField: 'updatedAt',
      text: 'Last Update',
      sort: true,
      formatter: (cell, row) => {
        return returnUserReadableDateTime(cell);
      },
    },
    {
      dataField: 'vanNo',
      text: 'Van No',
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => {
        return (
          <Badge bg={getTripBadgeStatus(cell)} className="badge-lg">
            {cell}
          </Badge>
        );
      },
    },
    {
      dataField: 'view',
      text: 'View',
      formatter: (cell, row) => <ViewModal rowId={row?.objectId} />, 

    },
  ];
  return columns;
};

export const delayedOrdersColumn = (navigate) => {
  const columns = [
    {
      dataField: 'orderDateandTime',
      text: 'Order Date',
      formatter: (cell, row) => {
        let date = new Date(
          new Date(cell?.iso).getTime() - 5.5 * 60 * 60 * 1000
        );
        return returnUserReadableDateTime(date);
      },
    },
    {
      dataField: 'orderNo',
      text: 'order No',
      sort: true,
    },
    {
      dataField: 'storeCode',
      text: 'Store Name',
    },

    {
      dataField: 'vanNo',
      text: 'Van No',
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
    },
    {
      dataField: 'delayedDays',
      text: 'Delayed Days',
    },
    {
      dataField: 'expectedDeliveryDate',
      text: 'ExpectedDelivery Date',
      formatter: (cell, row) => {
        let date = new Date(new Date(cell).getTime() - 5.5 * 60 * 60 * 1000);
        return returnUserReadableDate(date);
      },
    },
    {
      dataField: 'newExpectedDeliveryDate',
      text: 'New ExpectedDelivery Date',
      formatter: (cell, row) => {
        let date = new Date(new Date(cell).getTime() - 5.5 * 60 * 60 * 1000);
        return returnUserReadableDate(date);
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => {
        return (
          <Badge bg={getTripBadgeStatus(cell)} className="badge-lg">
            {cell}
          </Badge>
        );
      },
    },
    {
      dataField: 'view',
      text: 'View',
      formatter: (cell, row) => <ViewModal rowId={row?.objectId} />, 

    },
  ];
  return columns;
};

export const rejectedHistoryColumn = (navigate,handleShowMore) => {
  const columns = [
    {
      dataField: 'storeCode',
      text: 'Store',
    },
    {
      dataField : "zone",
      text : "zone"
    },
    {
      dataField: 'users',
      text: 'users',
      formatter: (cell, row) => {
        const allProducts =  Object.keys(cell).map(each => {
          return {
            username :  each=="undefined" ? "others" : each, 
            count : cell[each]
          }
        }).sort((a,b) => b.count - a.count ) || [];

        // Always show the first product (if exists)
        const productsToShow =
          allProducts.length > 0 ? allProducts.slice(0, 1) : [];

        return (
          <>
            {productsToShow.map((product) => (
              <div className="d-block" key={product?.username}>
                <span className="fw-bold">{product?.username}</span>
                <br />
                <span className="fw-normal">
                  Reject Count: {product?.count}
                </span>
                &nbsp;/&nbsp;
               
              </div>
            ))}
            <>
              {
                <Button
                  size="sm"
                  variant="light"
                  onClick={() => {
                    handleShowMore(allProducts,"users");
                    // console.log(`Showing modal for row: ${row?.objectId}`);
                  }}
                >
                  {allProducts.length === 1
                    ? 'View Users Details'
                    : `+ ${allProducts.length - 1} More Users(s)`}
                  &nbsp;&nbsp;
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              }
            </>
          </>
        );
      },
    },
    {
      dataField: 'reasonForRejection',
      text: 'Reason For Rejection',
      formatter: (cell, row) => {
        const allProducts =  Object.keys(cell).map(each => {
          return {
            reason : each=="undefined" ? "others" : each, 
            count : cell[each]
          }
        }).sort((a,b) => b.count - a.count ) || [];

        // Always show the first product (if exists)
        const productsToShow =
          allProducts.length > 0 ? allProducts.slice(0, 1) : [];

        return (
          <>
            {productsToShow.map((product) => (
              <div className="d-block" key={product?.reason}>
                <span className="fw-bold">{product?.reason}</span>
                <br />
                <span className="fw-normal">
                  Reject Count: {product?.count}
                </span>
                &nbsp;/&nbsp;
               
              </div>
            ))}
            <>
              {
                <Button
                  size="sm"
                  variant="light"
                  onClick={() => {
                    handleShowMore(allProducts,"reason");
                    // console.log(`Showing modal for row: ${row?.objectId}`);
                  }}
                >
                  {allProducts.length === 1
                    ? 'View Reason Details'
                    : `+ ${allProducts.length - 1} More Reason(s)`}
                  &nbsp;&nbsp;
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              }
            </>
          </>
        );
      },
    },
    {
      dataField: 'totalOrders',
      text: 'Rejected Count',
      sort: true,
    },
    {
      dataField: 'average',
      text: 'Avergage Time',
    }
  ];
  return columns;
};
export const manageHyperLocalDeliveriesColumns = (onRowSelect) => {
  const columns = [
    {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    },
    {
      dataField: 'pincode',
      text: 'Pincode',
    },
    {
      dataField: 'city',
      text: 'City',
    },
  ];
  return columns;
};

export const addTwoHoursOrNextDay = (date) => {
  const IST_OFFSET = 5.5 * 60 * 60 * 1000;
  const currentTime = new Date(date.getTime());
  const currentHour = currentTime.getUTCHours();
  const isWithinTimeRange = currentHour >= 5 && currentHour < 10;

  if (isWithinTimeRange) {
    return new Date(currentTime.getTime() + 2 * 60 * 60 * 1000);
  } else {
    const nextDay = new Date(currentTime);
    nextDay.setDate(nextDay.getDate() + 1);
    nextDay.setUTCHours(5, 30, 0, 0);
    return new Date(nextDay.getTime());
  }
};

export const getColumnsForExcelUpload = (data) => {
  const objectToShow = Object.keys(data?.[0] || {})?.map((each) => {
    if (each?.toLowerCase()?.includes('date')) {
      return {
        dataField: each,
        text: each,
        formatter: (cell, row) => {
          return returnUserReadableDate(convertExcelDateToJSDate(cell));
        },
      };
    }
    return {
      dataField: each,
      text: each,
    };
  });

  return objectToShow;
};

export let convertExcelDateToJSDate = (excelDate) => {
  if (typeof excelDate === 'number') {
    const baseDate = new Date(1899, 11, 30); // Excel epoch date
    return new Date(baseDate.getTime() + excelDate * 86400000);
  }
  return excelDate; // Return as-is if it's not a number
};

export const getColumnsForCashifyOrders = (onActionClick,isPending) => {
  return [{dataField : "orderNo", text:"Order No"},
  {dataField : "vanNo", text:"VanNo"},{dataField : 'orderAmount' , text : "amount"},{dataField : "paymentGateway", text:"paymentGateway"},{dataField : 'brand' , text : "Brand"}, {dataField : 'serialNo' , text : "serialNo"},{dataField:"cashifyQuoteId",text:"cashifyQuoteId"}, {
    dataField: 'productTitle',
    text: 'Product',
    formatter: (cell, row) => {
      return returnProductInfo([cell]);
    },
  },{dataField : "paymentType", text:"PG REF NO"},{dataField : "cashifyTransactionId", text:"Cashify Id"}, {dataField:"pineTransactionId",text:"pineTransactionId"}, {
    dataField: 'orderDateandTime',
    text: 'Order Date',
    formatter: (cell, row) => {
      let date = new Date(
        new Date(cell?.iso).getTime() - 5.5 * 60 * 60 * 1000
      );
      return returnUserReadableDateTime(date);
    },
  }, {
  dataField: 'status',
  text: 'Internalstatus',
  formatter: (cell, row) => {
    return (
      <div className="d-block">
        <div className="fw-bold">
          <span>
            &nbsp;&nbsp;
            <Badge bg={"info"} className="badge-lg">
             {cell}
            </Badge>
            &nbsp;&nbsp;
          </span>
        </div>
      </div>
    );
  },
},
 
 {
     dataField: 'cashifyStatus',
     text: 'IMEI Status',
     formatter: (cell, row) => {
       return (
         <div className="d-block">
           <div className="fw-bold">
             <span>
               &nbsp;&nbsp;
               <Badge bg={isPending ?"info"  :("success") } className="badge-lg">
                {cell}
               </Badge>
               &nbsp;&nbsp;
             </span>
           </div>
         </div>
       );
     },
   },
   {
     dataField: 'Action',
     text: 'action',
     headerClasses: 'rounded-0 p-3 h-auto',
     style: { textAlign: 'center' },
     formatter: (cell, row) => {
       return (
         <>
           <ActionButtonWithIcons
             options={isPending ? getActionOptionsForPending() : getActionOptions()}
             onActionClick={(option) => onActionClick(option, row)}
           />
         </>
       );
     },
   }]
 }
 
 const getActionOptions = () => {
 const options = [
   {icon : faEye, label : "View"},
   { icon: faUndo, label: "unblock" },
   { icon: faCancel, label: "cancel" },
 ];
 
 return options
 } 

  
 const getActionOptionsForPending = () => {
  const options = [
   {icon : faEye, label : "View"},
    { icon: faCheckDouble, label: "Mark as Verified" },
    { icon: faUndo, label: "unblock"},
    { icon: faCancel, label: "cancel"},
  ];
  return options
  } 
