import Parse from 'parse';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setVehiclesListInStore } from '../../actions/drivers';
import { updateToastInfo } from '../../actions/settings';
import { updateSpinnerState } from '../../actions/spinner';
import ParcelStatusPage from '../../components/common/parcelStatusPages';
import {
  getVehiclesListInZone,
  updateVehiclesInfo,
} from '../../parse-functions/vehicles';
import { Routes } from '../../router/routes';
import { convertArrayToObj } from '../../utils/json';
import { returnVehicleListTableColumns } from './vehicleUtils';
import { markVehicleAsSelected } from '../../actions/vehicles';
import ConfirmationModal from '../../components/common/confirmationModal';
import VehicleUpdateModal from './vehicleUpdateModal';
import ProcessingModal from './ProcessingModal';

const VendorsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const zone = useSelector((state) => state?.user?.warehouse?.zone || '');
  const vehiclesList = useSelector(
    (state) => state?.drivers?.driversList || {}
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showVehicleUpdateModal, setShowVehicleUpdateModal] = useState(false);
  const [showProcessingModal, setShowProcessingModal] = useState(false);
  const [updateMode, setUpdateMode] = useState('');

  const selectedVehicles = Object.values(vehiclesList)?.filter(
    (vehicle) => vehicle?.isSelected === true
  );
  const breadCrumbItems = [
    {
      title: t('Vehicles'),
      link: Routes.Vehicles.List.path,
    },
    {
      title: t('List'),
      link: Routes.Vehicles.List.path,
    },
  ];

  useEffect(() => {
    if (Object.keys(vehiclesList) < 1 && zone) {
      getAvailableVehicleList();
    }
  }, [zone]);
  const getAvailableVehicleList = async () => {
    dispatch(updateSpinnerState(true));
    try {
      const filters = {
        zone,
        // status: "active",
        getAllVehicles: isAdmin,
      };
      const vehicles = await getVehiclesListInZone(filters);
      dispatch(
        setVehiclesListInStore(
          convertArrayToObj(JSON.parse(JSON.stringify(vehicles)), 'objectId')
        )
      );
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('Vehicle List fetched'),
          message: t('All the Vehicles for the zone are listed here'),
        })
      );
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error'),
          message: t(e.message),
        })
      );
    }
  };

  const onRefreshClick = () => {
    getAvailableVehicleList();
  };
  const onRowSelect = (selected, objectId) => {
    dispatch(markVehicleAsSelected({ objectId, selected }));
  };
  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };
  const handleShowVehicleUpdateModal = () => {
    setShowVehicleUpdateModal(true);
  };
  const handleSelectedRowsUpdate = (actionId) => {
    if (selectedVehicles?.length < 1) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Loader Not Selected'),
          message: t(
            'Please select a loader from the list before applying operation'
          ),
        })
      );
      return;
    }
    if (selectedVehicles?.length > 0) {
      if (selectedVehicles?.length > 1 && actionId === 'edit') {
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Max 1 vehicle to select'),
            message: t('Please select maximum of 1 vehicle for editing'),
          })
        );
        return;
      }
      // we can do edit and delete here
      switch (actionId) {
        case 'delete': {
          setUpdateMode('Delete');
          handleShowDeleteModal();
          break;
        }
        case 'edit': {
          handleShowVehicleUpdateModal();
          setUpdateMode('Edit');
          break;
        }
        case 'activate': {
          setUpdateMode('Activate');
          handleShowDeleteModal();
          break;
        }
        default: {
          break;
        }
      }
    }
  };
  const handleOnConfirmClick = async (data) => {
    try {
      if (data[0].fromDate || data[0].toDate) {
        setShowProcessingModal(true);
      } else {
        dispatch(updateSpinnerState(true));
      }
      await updateVehiclesInfo({ data });
      await getAvailableVehicleList();
      setShowProcessingModal(false);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: `Vehicles ${updateMode} Successful`,
          message: '',
        })
      );
      setShowDeleteModal(false);
      setShowVehicleUpdateModal(false);
    } catch (e) {
      setShowProcessingModal(false);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Vehicle Update Failed',
          message: e.message ? e.message : 'Please contact support for help',
        })
      );
    }
  };
  const handleModalOnConfirmClick = () => {
    const vehicleData = [];
    // eslint-disable-next-line no-unused-expressions
    selectedVehicles?.forEach((vehicle) => {
      const data = {};
      data.objectId = vehicle.objectId;
      data.dataFields = {
        status: updateMode === 'Delete' ? 'deleted' : 'active',
        actionLog: {
          action: updateMode === 'Delete' ? 'deleted' : 'activated',
          user: Parse.User.current().getUsername(),
          time: new Date().getTime(),
        },
      };
      vehicleData.push(data);
    });
    handleOnConfirmClick(vehicleData);
  };
  const handleVehicleUpdateInfo = (vehicleObject) => {
    const vehicleUpdateData = [
      {
        objectId: vehicleObject.objectId,
        dataFields: {
          contactNumber: vehicleObject.contactNumber,
          driverName: vehicleObject.driverName,
          paymentMode: vehicleObject.paymentMode,
          vehicleType: vehicleObject.vehicleType,
          vendorName: vehicleObject.vendorName,
          vendorObjectId: vehicleObject.vendorObjectId,
          paymentAmount: parseInt(vehicleObject.paymentAmount || 0),
          vehicleNumber: vehicleObject.vehicleNumber,
          zone: vehicleObject.zone,
          storeName: vehicleObject.storeName,
          actionLog: {
            action: 'updated',
            user: Parse.User.current().getUsername(),
            time: new Date().getTime(),
          },
        },
        fromDate: vehicleObject.fromDate,
        toDate: vehicleObject.toDate,
      },
    ];
    handleOnConfirmClick(vehicleUpdateData);
  };
  return (
    <>
      <ParcelStatusPage
        pageType={'vehiclesList'}
        showDateFilters={false}
        tableData={vehiclesList}
        tableColumns={returnVehicleListTableColumns(onRowSelect)}
        handleRefresh={onRefreshClick}
        pageTitle={t('Vehicles List')}
        breadCrumbItems={breadCrumbItems}
        pageSubTitle={t(
          'All the vehicles who are associated with us and are active'
        )}
        searchPlaceHolder={t('Search By Vehicle Number')}
        enableBulkActions={selectedVehicles?.length > 0}
        bulkActionOptions={[
          {
            label: 'Edit',
            value: 'edit',
          },
          {
            label: 'Delete / Remove',
            value: 'delete',
          },
          {
            label: 'Activate',
            value: 'activate',
          },
        ]}
        handleItemBulkUpdate={handleSelectedRowsUpdate}
      />
      <ConfirmationModal
        showModal={showDeleteModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowDeleteModal(false);
        }}
        modalBody={t(
          `Are you sure you want to ${updateMode?.toLowerCase()} these vehicles`
        )}
        modalTitle={t(`${updateMode} Vehicle(s)`)}
      />
      <VehicleUpdateModal
        closeModal={() => {
          setShowVehicleUpdateModal(false);
        }}
        vehicle={selectedVehicles?.[0] || {}}
        showModal={showVehicleUpdateModal}
        onConfirm={handleVehicleUpdateInfo}
      />
      <ProcessingModal
        closeModal={() => {
          setShowProcessingModal(false);
        }}
        showModal={showProcessingModal}
        message={'Updating data. Please Wait...'}
      />
    </>
  );
};
export default VendorsList;
