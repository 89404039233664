import React from 'react';
import { getTripBadgeStatus } from '../../utils/trips';
import { returnUserReadableDateTime } from '../../utils/datetime';
import { Badge, Button } from '@themesberg/react-bootstrap';
import {
  getTableRowCheckBox,
  returnCustomerAddressString,
} from '../../utils/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faImage } from '@fortawesome/free-regular-svg-icons';
import StatusCard from '../oms/interBranchTransfers/StatusCard';

export const VouchersSentColumns = (
  onRowSelect,
  showItemsModal,
  isFinance = false
) => {
  const columns = [
    // {
    //   dataField: 'objectId',
    //   text: 'Select',
    //   formatter: (cell, row) => {
    //     return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
    //   },
    // },
    {
      dataField: 'voucherNumber',
      sort: true,
      text: 'Voucher No.',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">{cell}</div>
          </div>
        );
      },
    },
    {
      dataField: 'createdAt',
      text: 'Created Date',
      formatter: (cell, row) => {
        return returnUserReadableDateTime(cell);
      },
    },
    {
      dataField: 'tripsDetails',
      text: 'Trips',
      formatter: (cell, row) => {
        const allTrips = Object.values(row?.tripsDetails);
        const showMore = allTrips?.length > 1;
        const tripsToShow =
          allTrips?.length > 1 ? allTrips?.splice(0, 1) : allTrips;
        return (
          <>
            {tripsToShow?.map((trip) => {
              return (
                <div className="d-block">
                  <span className="fw-bold">{trip?.tripNumber}</span>
                  <br />
                  <span className="fw-normal">{trip.paymentType}</span>
                  &nbsp;/&nbsp;
                  <span className="fw-normal">{trip.amount}</span>
                </div>
              );
            })}
            <>
              {showMore && (
                <>
                  {`+ ${allTrips?.length} More Trip(s)`}&nbsp;&nbsp;
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => {
                      showItemsModal(row?.objectId);
                    }}
                  >
                    Show Details
                  </Button>
                </>
              )}
            </>
          </>
        );
      },
    },
    {
      dataField: 'totalAmount',
      text: 'total',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">{cell}</div>
          </div>
        );
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">
              <span>
                &nbsp;&nbsp;
                <Badge
                  bg={getTripBadgeStatus(
                    row?.status === 'created' ? 'pending' : row?.status
                  )}
                  className="badge-lg"
                >
                  {cell}
                </Badge>
                &nbsp;&nbsp;
              </span>
            </div>
          </div>
        );
      },
    },
  ];

  if (isFinance) {
    columns.splice(1, 0, {
      dataField: 'createdByBranch',
      text: 'Created Branch',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    });
  }

  return columns;
};

export const voucherApprovedColumns = (onSignatureClick, onPrintClick) => {
  const columns = [
    {
      dataField: 'voucherNumber',
      text: 'Voucher No.',
    },
    {
      dataField: 'tripNumber',
      text: 'trip number',
    },
    {
      dataField: 'vehicleNumber',
      text: 'vehicle Info',
      formatter: (cell, row) => {
        return (
          <>
            <div>Vehicle No: {cell}</div>
            <div>Drive Name: {row?.driverName || ''}</div>
          </>
        );
      },
    },
    {
      dataField: 'key',
      text: 'invoice No',
      formatter: (cell, row) => {
        const customerName = cell?.split('-')[0] || '-';
        const remarks = row?.invoiceRemarks || '-';
        return (
          <>
            <div>Customer: {customerName}</div>
            <div>Invoice Extra Pay: {row?.invoiceAmount || 0}</div>
            <div style={{ whiteSpace: 'wrap' }}>
              {returnCustomerAddressString(`Remarks: ${remarks}`)}
            </div>
          </>
        );
      },
    },
    {
      dataField: 'approvalDate',
      text: 'Payment Approval Date',
      formatter: (cell, row) => {
        return cell ? returnUserReadableDateTime(cell) : '-';
      },
    },
    {
      dataField: 'approvedBy',
      text: 'Approved By',
      formatter: (cell, row) => {
        return <div className="d-block">{cell ? cell : '-'}</div>;
      },
    },
    {
      dataField: 'totalAmount',
      text: 'order details',
      formatter: (cell, row) => {
        return (
          <>
            <div>Total Orders: {row?.totalOrders || 0}</div>
            <div>Total Cost: {cell}</div>
          </>
        );
      },
    },
    {
      dataField: 'invoicePaymentType',
      text: 'paymentType',
    },
    {
      dataField: 'status',
      text: 'status',
      formatter: (cell, row) => {
        return (
          <StatusCard
            status={cell}
            bgColor={cell === 'paid' ? 'rgba(212, 247, 232, 1)' : '#FDEDD9'}
            color={cell === 'paid' ? 'rgba(33, 168, 113, 1)' : '#F4921B'}
            minWidth={'7em'}
            height={'2.4rem'}
          />
        );
      },
    },
    {
      dataField: 'signature',
      text: 'signature proof',
      formatter: (cell, row) => {
        return (
          <Button
            size="sm"
            variant="light"
            onClick={() => onSignatureClick(cell)}
          >
            <FontAwesomeIcon icon={faImage} /> &nbsp;&nbsp; signature
          </Button>
        );
      },
    },
    {
      dataField: 'print',
      text: 'print',
      formatter: (cell, row) => {
        return (
          <Button
            size="sm"
            variant="light"
            onClick={() => onPrintClick(row?.objectId)}
          >
            <FontAwesomeIcon icon={faFile} /> &nbsp;&nbsp; print
          </Button>
        );
      },
    },
  ];
  return columns;
};
