import React, { useEffect, useState } from 'react';
import Barcode from 'react-barcode';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateSpinnerState } from '../../actions/spinner';
import { updateToastInfo } from '../../actions/settings';
import { getPaymentVoucherById } from '../../parse-functions/payments';
import { useTranslation } from 'react-i18next';
import { returnUserReadableDateTime } from '../../utils/datetime';

const VoucherPrint = () => {
  const { t } = useTranslation();
  const { objectId } = useParams();
  const dispatch = useDispatch();
  const [voucherData, setVoucherData] = useState({});
  // const [isHalfPage, setIsHalfPage] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const res = await getPaymentVoucherById({ id: objectId });
        setVoucherData(res);
        // setIsHalfPage(
        //   res.tripsDetails && Object.keys(res.tripsDetails).length <= 4
        // );
        dispatch(updateSpinnerState(false));
        setPrintAttributesToPage();
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: 'Failed to get Voucher Data',
            message: t(
              'Failed to get Voucher data from server. Please try again or contact support'
            ),
          })
        );
      }
    };
    fetchData();
  }, [objectId, dispatch, t]);

  const setPrintAttributesToPage = () => {
    const css =
      '@page { size: A4 potrait; margin: 0mm 0mm 0mm 0mm; color: #000000 !important; }';
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.type = 'text/css';
    style.media = 'print';

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
    head.appendChild(style);
    window.print();
  };

  const containerStyle = {
    color: '#000000',
    height: '100vh',
  };

  const tableStyle = {
    color: '#000000',
    maxHeight: '70vh',
    overflowY: 'auto',
  };

  const listItemStyle = {
    padding: '0',
    margin: '0',
    fontSize: '14px',
  };

  const thTdStyle = {
    padding: '5px',
    fontSize: '10px',
    textAlign: 'center',
    whiteSpace: 'wrap',
  };

  const trStyle = {
    margin: '2px 0',
  };

  return (
    <div className="d-flex w-100" style={containerStyle}>
      <div className="px-4 py-2 card border-0 w-100">
        <table className="table table-borderless">
          <thead>
            <tr>
              <td
                style={{
                  borderTopStyle: 'solid',
                  borderTopWidth: '1pt',
                  borderBottomStyle: 'solid',
                  borderBottomWidth: '1pt',
                  borderRightStyle: 'solid',
                  borderRightWidth: '1pt',
                }}
                colSpan="11"
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={require('../../assets/img/brand/vijay-sales-logo.svg')}
                    alt="Vijay Sales"
                    width="297"
                    height="52"
                  />
                  <div
                    style={{
                      paddingTop: '5pt',
                      fontWeight: 'bold',
                    }}
                  >
                    VIJAY SALES INDIA PVT LTD
                  </div>
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>{voucherData?.createdByBranch}</div>
                  <div style={{ fontWeight: 'bold' }}>Payment Voucher</div>
                </div>
              </td>
            </tr>
          </thead>
        </table>
        <div className="row mb-1" style={{ borderTop: '1px solid' }}>
          <div
            style={{
              fontSize: '12px',
              display: 'grid',
              gap: '0.5rem',
              gridTemplateColumns: 'repeat(3, 1fr)',
              textAlign: 'left',
            }}
          >
            <div>{voucherData?.address}</div>
            {/* <div>
              {' '}
              <strong>State:</strong> {voucherData?.address}
            </div> */}
            <div>
              {' '}
              <strong>GST No: </strong>
              {voucherData?.configData?.GSTNumber}
            </div>
            <div>
              <strong>Pincode:</strong> {voucherData?.pincode}
            </div>
            <div>
              <strong>Date:</strong>
              {voucherData?.actionLog?.length > 0
                ? returnUserReadableDateTime(voucherData.actionLog[0].time)
                : '-'}
            </div>
          </div>
        </div>
        <div
          className="row mb-1"
          style={{ borderTop: '1px solid', borderBottom: '1px solid' }}
        >
          <div
            style={{
              fontSize: '12px',
              display: 'grid',
              gap: '0.5rem',
              gridTemplateColumns: 'repeat(3, 1fr)',
              textAlign: 'left',
            }}
          >
            <div>
              <strong>Name: </strong>
              {voucherData?.vendorName}
            </div>
            <div>
              <strong>Contact: </strong>
              {voucherData?.contactNumber}
            </div>
            <div>
              <strong>GST No:</strong> {voucherData?.gstNumber}
            </div>
            <div>
              <strong>Payment Mode:</strong> {voucherData?.paymentMode}
            </div>
            <div>
              <strong>Payment Type:</strong> {voucherData?.paymentType}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="table-responsive" style={tableStyle}>
              <table className="mb-0 table">
                <thead className="bg-light border-top">
                  <tr style={trStyle}>
                    <th className="border-0" style={thTdStyle}>
                      Trip Number
                    </th>
                    <th className="border-0" style={thTdStyle}>
                      Customer Name
                    </th>
                    <th className="border-0" style={thTdStyle}>
                      Payment Type
                    </th>
                    <th className="border-0" style={thTdStyle}>
                      Remarks
                    </th>
                    <th className="border-0" style={thTdStyle}>
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody className="border-0" style={thTdStyle}>
                  {voucherData?.tripsDetails &&
                    Object.entries(voucherData?.tripsDetails)?.map(
                      ([tripNumber, trip], tripIndex) =>
                        trip?.invoices?.map((invoice, invoiceIndex) => (
                          <tr
                            key={`${tripIndex}-${invoiceIndex}`}
                            style={trStyle}
                          >
                            <td className="border-0" style={thTdStyle}>
                              {tripNumber}
                            </td>
                            <td className="border-0" style={thTdStyle}>
                              {invoice?.key?.split('-')[0] || '-'}
                            </td>
                            <td className="border-0" style={thTdStyle}>
                              {invoice?.paymentType
                                ? invoice.paymentType
                                    .replace(/([A-Z])/g, ' $1') // Add space before capital letters
                                    .split(' ')
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1).toLowerCase()
                                    ) // Capitalize first letter of each word
                                    .join(' ')
                                : '-'}
                            </td>
                            <td className="border-0" style={thTdStyle}>
                              {invoice?.remarks || '-'}
                            </td>
                            <td className="border-0" style={thTdStyle}>
                              {invoice?.amount || '-'}
                            </td>
                          </tr>
                        ))
                    )}
                  <tr style={{ borderTop: '1px solid' }}>
                    <td
                      colSpan="3"
                      className="text-end fw-bold"
                      style={thTdStyle}
                    >
                      Total
                    </td>
                    <td className="fw-bold" style={thTdStyle}>
                      {voucherData?.totalAmount || '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          style={{
            fontSize: '12px',
            display: 'grid',
            gap: '0.5rem', // Reduced gap between rows
            gridTemplateRows: 'auto auto', // Automatically adjust row heights
            gridTemplateColumns: 'repeat(3, 1fr)', // 3 columns
            textAlign: 'center',
          }}
        >
          {/* Top row: Three items */}
          <div>Customer Care No: 022 - 66227722</div>
          <div>Delivery Helpline No: 022 69449800</div>
          <div>
            <strong>For Vijay Sales (India) Pvt Ltd</strong>
          </div>

          {/* Bottom row: Barcode and Thank You */}
          <div
            style={{
              gridColumn: '1 / 2',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Barcode
              height={20}
              font='"Nunito Sans", sans-serif'
              fontSize={12}
              width={'1rem'}
              value={voucherData?.voucherNumber}
            />
          </div>
          <div
            style={{
              gridColumn: '2 / 4',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <strong>Thank You, Come again</strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoucherPrint;
