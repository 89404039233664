import React, { useEffect, useState } from 'react';
import BreadCrumbComponent from '../../components/common/Breadcrumb';
import { Button, ButtonGroup, Form } from '@themesberg/react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRotateLeft,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import ExcelDownloadReport from '../../components/common/excel/download';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../actions/spinner';
import { updateToastInfo } from '../../actions/settings';
import DateRangeSelector from '../../components/common/DateRangeSelector';
import DateMonthSelector from '../../components/common/dateMonthSelector';
import { getPaymentsReport } from '../../parse-functions/reports';
import { getVendorsListInRegion } from '../../parse-functions/vendors';
import { setVendorsListInStore } from '../../actions/vendors';
import { convertArrayToObj } from '../../utils/json';
import EditableDropDown from '../../components/common/EditableDropdown';
import { arrayToDropdownOptions } from '../../utils/sms/arrayToDropdownOptions';
import { unpaidTripsColumns } from './utils';
import NewTable from '../../components/common/NewTable';
import TripViewModal from './TripViewModal';
import { setUnpaidTripsInStore } from '../../actions/trips';
import {
  updateTripPayment,
  updateTripPaymentsStatus,
} from '../../parse-functions/payments';

const UnpaidTrips = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialFormInput = {
    filterType: 'Month',
    vendor: '',
    vehicleNumber: '',
    zone: '',
  };
  const [showFilterOptions, setShowFilterOptions] = useState(true);
  const [dataToDownload, setDataToDownload] = useState([]);
  const [vendorOptions, setVendorOptions] = useState({});
  const vendorsList = useSelector((state) => state?.vendors?.vendorsList || {});
  const unpaidTrips = useSelector((state) => state?.trips?.unpaidTrips || []);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const configData = useSelector((state) => state?.user?.config);
  const [formInput, setFormInput] = useState(initialFormInput);
  const [showTripsModal, setShowTripsModal] = useState(false);
  const [tripData, setTripData] = useState(unpaidTrips);

  const breadCrumbItems = [
    {
      title: t('Payments'),
      link: '',
    },
    {
      title: t('Unpaid Trips'),
      link: '',
    },
  ];

  const handleGetAvailableAnalyticsData = async (requestProps) => {
    dispatch(updateSpinnerState(true));
    try {
      const dataList = await getPaymentsReport(requestProps);
      setFormInput(requestProps);
      setDataToDownload(dataList);
      dispatch(setUnpaidTripsInStore(dataList));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('All data fetched'),
        })
      );
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error in getting active analytics data'),
          message: e.message,
        })
      );
    }
  };
  const handleFilterSubmit = async (startDate, endDate) => {
    await handleGetAvailableAnalyticsData({
      ...formInput,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      reportType: 'unpaidTrips',
    });
  };

  const onRowSelect = (selected, objectId) => {
    setTripData((prev) =>
      prev.map((item) =>
        item.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };

  const selectAllClicked = () => {
    if (!selectAll) {
      setTripData((prev) =>
        prev.map((item) => ({
          ...item,
          isSelected: true,
        }))
      );
    } else {
      setTripData((prev) =>
        prev.map((item) => ({
          ...item,
          isSelected: false,
        }))
      );
    }
    setSelectAll((prev) => !prev);
  };
  useEffect(() => {
    const fetchVendor = async () => {
      if (Object.keys(vendorsList).length > 0) {
        const vendorObj = {};
        Object.values(vendorsList).forEach(
          (item) => (vendorObj[item['vendorName']] = item['objectId'])
        );
        setVendorOptions(vendorObj);
        return;
      }
      dispatch(updateSpinnerState(true));
      try {
        const filters = {
          status: 'active',
          getAllVendors: true,
        };
        let vendors = await getVendorsListInRegion(filters);
        dispatch(
          setVendorsListInStore(
            convertArrayToObj(JSON.parse(JSON.stringify(vendors)), 'objectId')
          )
        );
        vendors = JSON.parse(JSON.stringify(vendors));
        const vendorObj = vendors?.reduce((acc, row) => {
          acc[row['vendorName']] = row['objectId'];
          return acc;
        }, {});
        setVendorOptions(vendorObj);
        dispatch(updateSpinnerState(false));
      } catch (e) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Error'),
            message: t(e.message),
          })
        );
      }
    };
    fetchVendor();
  }, []);

  useEffect(() => {
    setTripData(unpaidTrips);
  }, [unpaidTrips]);

  const handleViewClick = (id) => {
    const trips = tripData.find((item) => item.objectId === id)?.trips;
    if (trips.length > 0) {
      setSelectedData(trips);
      setShowTripsModal(true);
    } else {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error'),
          message: t(`No Trips Closed for this vehicle!`),
        })
      );
    }
  };

  const handleSaveClick = async (saveData) => {
    try {
      dispatch(updateSpinnerState(true));
      await updateTripPayment(saveData);
      const newData = tripData.map((item) => {
        const updatedTrips = item.trips.map((trip) =>
          trip.objectId === saveData.tripId
            ? { ...trip, totalAmount: saveData.totalAmount }
            : trip
        );
        const updatedTotalAmount = updatedTrips.reduce(
          (sum, trip) => sum + (trip.totalAmount || 0),
          0
        );
        return {
          ...item,
          trips: updatedTrips,
          totalAmount: updatedTotalAmount,
        };
      });
      dispatch(setUnpaidTripsInStore(newData));
      const newSelectedData = selectedData.map((item) =>
        item.objectId === saveData.tripId
          ? { ...item, totalAmount: saveData.totalAmount }
          : item
      );
      setSelectedData(newSelectedData);
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to save data!'),
          message: `${error.message}`,
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };
  const handleInvoiceClicked = async () => {
    const tripIds = tripData
      .filter((item) => item.isSelected)
      .flatMap((item) =>
        item.trips
          .filter((trip) => trip.status === 'completed')
          .map((trip) => trip.objectId)
      );
    try {
      dispatch(updateSpinnerState(true));
      await updateTripPaymentsStatus({ tripIds, status: 'invoiced' });
      await handleFilterSubmit(formInput.startDate, formInput.endDate);
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Cannot Update!'),
          message: `${error.message}`,
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <BreadCrumbComponent items={breadCrumbItems} />
          <h4>{t('Unpaid Trips Report')}</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '4px',
              }}
            >
              <Button
                variant={showFilterOptions ? 'primary' : 'white'}
                onClick={() => {
                  setShowFilterOptions((prev) => !prev);
                }}
                style={{
                  width: '150px',
                  height: '38px',
                  borderRadius: 0,
                }}
                size="sm"
              >
                <FontAwesomeIcon icon={faSliders} />
                &nbsp;&nbsp;{t('Filter')}
              </Button>
              <ButtonGroup>
                <ExcelDownloadReport
                  data={dataToDownload || []}
                  fileName={'vehicle-payments'}
                />
              </ButtonGroup>
            </div>
          </div>
        </div>
      </div>
      {showFilterOptions && (
        <>
          <div
            className="table-settings mt-4 mb-4 p-3"
            style={{
              background: 'white',
              transition: 'max-height 0.7s ease-in-out',
            }}
          >
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '20px',
                width: '100%',
                backgroundColor: 'white',
              }}
            >
              <Form.Group>
                <Form.Label>Date Type</Form.Label>
                <EditableDropDown
                  onChange={(e) =>
                    setFormInput((prev) => ({
                      ...prev,
                      filterType: e.value,
                    }))
                  }
                  options={[
                    { label: 'Month', value: 'Month' },
                    { label: 'Day', value: 'Day' },
                  ]}
                  placeholder={'Select an Option'}
                  value={formInput['filterType']}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Vendor Name</Form.Label>
                <EditableDropDown
                  id={'vendor'}
                  onChange={(e) =>
                    setFormInput((prev) => ({
                      ...prev,
                      vendor: e.value,
                    }))
                  }
                  backgroundColor="#eaedf2"
                  value={formInput['vendor']}
                  options={arrayToDropdownOptions(Object.keys(vendorOptions))}
                  placeholder={'Select Vendor Name'}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Vehicle Number</Form.Label>
                <Form.Control
                  style={{
                    height: 38,
                    width: '100%',
                    backgroundColor: '#eaedf2',
                  }}
                  type="text"
                  value={formInput['vehicleNumber']}
                  onChange={(e) => {
                    e.persist();
                    setFormInput((prev) => ({
                      ...prev,
                      vehicleNumber: e.target.value,
                    }));
                  }}
                  placeholder="Enter the Vehicle No."
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Zone</Form.Label>
                <EditableDropDown
                  onChange={(e) =>
                    setFormInput((prev) => ({
                      ...prev,
                      zone: e.value,
                    }))
                  }
                  options={arrayToDropdownOptions(
                    Object.keys(configData?.zones)
                  )}
                  placeholder={'Select an Option'}
                  value={formInput['zone']}
                />
              </Form.Group>
              <Form.Group className="d-flex align-items-end">
                {formInput.filterType === 'Day' ? (
                  <DateRangeSelector
                    filtersValue={formInput}
                    onSubmit={handleFilterSubmit}
                  />
                ) : (
                  <DateMonthSelector
                    filtersValue={formInput}
                    onSubmit={handleFilterSubmit}
                  />
                )}
              </Form.Group>
            </div>
            <div
              style={{
                gridColumn: 'span 3',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <Button
                variant="white"
                onClick={() => setFormInput(initialFormInput)}
                style={{
                  width: '150px',
                  height: '38px',
                  borderRadius: 0,
                  background: '#EAEDF2',
                }}
              >
                <FontAwesomeIcon icon={faArrowRotateLeft} />
                &nbsp;&nbsp;{t('Reset')}
              </Button>
            </div>
          </div>
        </>
      )}
      <div className="d-flex justify-content-between align-items-center p-2 mt-3 mb-2">
        <div
          className="d-flex align-items-center p-2"
          style={{
            width: '125px',
            height: '38px',
            borderRadius: 0,
            backgroundColor: 'white',
          }}
        >
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="Select All"
              checked={selectAll}
              onChange={selectAllClicked}
              disabled={tripData.length === 0}
            />
          </Form.Group>
        </div>
        <div className="d-flex gap-3">
          <Button
            variant="white"
            onClick={() => {
              handleInvoiceClicked();
            }}
            style={{
              width: '150px',
              height: '38px',
              borderRadius: 0,
            }}
            size="sm"
            disabled={tripData.filter((item) => item.isSelected).length === 0}
          >
            {t('Generate Invoice')}
          </Button>
        </div>
      </div>
      <NewTable
        data={tripData}
        columns={unpaidTripsColumns({ onRowSelect, handleViewClick })}
        id={'unpaidTripsColumns'}
      />
      {selectedData.length > 0 && (
        <TripViewModal
          show={showTripsModal}
          handleClose={() => {
            setShowTripsModal(false);
            setSelectedData([]);
          }}
          tripsData={selectedData}
          handleSaveClick={handleSaveClick}
        />
      )}
    </>
  );
};
export default UnpaidTrips;
