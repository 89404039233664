import React, { useEffect, useState } from 'react';
import Parse from 'parse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faPowerOff,
  faUnlockAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
  Image,
} from '@themesberg/react-bootstrap';

import BgImage from '../assets/img/illustrations/signin.svg';
import VijaySalesLogo from '../assets/img/brand/vijay-sales-logo.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  setApplicationConfigInState,
  setCurrentUserInState,
  setCurrentWarehouseInfoInStore,
  setUserPreferencesInStore,
  setCurrentUserInfoInState,
  setCurrentUserPermissionsInStore,
} from '../actions/user';
import { updateSpinnerState } from '../actions/spinner';
import { Routes } from '../router/routes';
import { useNavigate } from 'react-router-dom';
import { getApplicationConfig } from '../parse-functions/config';
import { getUserPreferences } from '../parse-functions/preferences';
import { getCurrentWarehouseInfo } from '../parse-functions/warehouse';
import { StyledLoader } from '../components/common/styles';
import { removeUnusedWarehousesFromList } from '../utils/warehouse';
import {
  getAccountManagerInfo,
  getBrandManagerInfo,
  getBrandVendorInfo,
} from '../parse-functions/sms/getUserInfo';
import { getUserPermissions } from '../parse-functions/roles';

export default () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const [username, setUserName] = useState(null);
  const [password, setPassword] = useState(null);
  const [usernameError, setUserNameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (Parse.User.current()) {
      if (
        Object.keys(state?.user?.config).length > 0 ||
        Object.keys(state?.user?.preferences).length > 0 // ||
        // Object.keys(state?.user?.warehouse).length > 0
      ) {
        setUserAndRedirect(Parse.User.current());
      }
    }
  }, []);

  const setUserAndRedirect = async (user, preferences, permissions) => {
    try {
      dispatch(updateSpinnerState(true));
      dispatch(setCurrentUserInState(user));
      // const storeInfo = await
      dispatch(updateSpinnerState(false));
      // if (preferences?.applications?.[0] === 'sms') {
      //   return navigate(Routes.SMS.Target.ActiveSchemes.path);
      // }
      // if (preferences?.applications?.[0] === 'oms') {
      //   return navigate(Routes.OMS.Orders.Assigned.path);
      // }
      // if (preferences?.userType === 'securityGuard') {
      //   return navigate(Routes.SecurityGuard.EntryList.path);
      // }
      // if (preferences?.applications?.[0] === 'wms') {
      //   return navigate(Routes.WMS.Target.Assigned.path);
      // }
      // if (
      //   preferences?.isCCManager ||
      //   preferences?.isBranchCCUser ||
      //   preferences?.isCCUser
      // ) {
      //   return navigate(Routes.Analysis.DeliveryData.path);
      // }
      if (localStorage.getItem('redirectionUrl')) {
        const url = localStorage.getItem('redirectionUrl');
        localStorage.removeItem('redirectionUrl');
        return navigate(url);
      }
      if (preferences?.isSuperAdmin || preferences?.isAdmin) {
        return navigate(Routes.HomePage.path);
      }
      return navigate(Object.keys(permissions)[0]);
    } catch (e) {
      console.log(e);
      if (Parse.User.current()) {
        await Parse.User.logOut();
        return navigate(Routes.Login.path);
      }
    }
  };
  const handleFormSubmit = async () => {
    if (!username?.length) {
      setUserNameError('Username cannot be empty');
    }
    if (!password?.length) {
      setPasswordError('Password cannot be empty');
    }
    if (!usernameError && !passwordError) {
      dispatch(updateSpinnerState(true));
      try {
        setShowLoader(true);
        const user = await Parse.User.logIn(username, password);
        // handle all the API calls for the login time
        // set User info here
        dispatch(setCurrentUserInState(Parse.User.current()));
        const config = await getApplicationConfig();
        dispatch(setApplicationConfigInState(config));
        // get preferences and set it
        const preferences = await getUserPreferences();
        dispatch(setUserPreferencesInStore(preferences));

        const permissions = await getUserPermissions({
          role: preferences?.roles,
        });
        dispatch(setCurrentUserPermissionsInStore(permissions));

        if (preferences.isBrandManager) {
          const userInfo = await getBrandManagerInfo({
            id: Parse.User.current().id,
          });
          dispatch(setCurrentUserInfoInState(userInfo));
        }

        if (preferences.isBrandVendor) {
          const userInfo = await getBrandVendorInfo({
            id: Parse.User.current().id,
          });
          dispatch(setCurrentUserInfoInState(userInfo));
        }

        if (preferences.isAccountManager) {
          const userInfo = await getAccountManagerInfo({
            id: Parse.User.current().id,
          });
          dispatch(setCurrentUserInfoInState(userInfo));
        }
        // set Warehouse details in store
        if (
          !preferences.isCCUser &&
          !preferences.isCCManager &&
          !preferences.isBrandManager &&
          !preferences.isAccountManager &&
          !preferences.isBrandVendor
        ) {
          const warehouseInfo = await getCurrentWarehouseInfo({
            warehouseName: preferences?.storeName,
            isSuperAdmin: preferences.isSuperAdmin,
            isBrandManager: preferences.isBrandManager,
            isAccountManager: preferences.isAccountManager,
            isBrandVendor: preferences.isBrandVendor,
            zone: preferences?.zone,
          });
          const finalWarehouseList = removeUnusedWarehousesFromList(
            warehouseInfo.otherWarehousesInZone || []
          );
          warehouseInfo.otherWarehousesInZone = finalWarehouseList;
          dispatch(setCurrentWarehouseInfoInStore(warehouseInfo));
          dispatch(updateSpinnerState(false));
        }

        setUserAndRedirect(user, preferences, permissions);
        setShowLoader(false);
      } catch (e) {
        console.log(e);
        setShowLoader(false);
        setUserNameError('Invalid Username');
        setPasswordError('Invalid Password');
      }
    }
  };
  return (
    <StyledLoader active={showLoader} spinner>
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row
              className="justify-content-center form-bg-image"
              style={{ backgroundImage: `url(${BgImage})` }}
            >
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <Image src={VijaySalesLogo} height={80} width={400} />
                  </div>
                  <Form.Group id="username" className="mt-4 mb-4">
                    <Form.Label>Your username</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        isInvalid={usernameError}
                        onChange={(event) => {
                          setUserName(event.target.value);
                          if (event.target.value?.length > 0) {
                            setUserNameError(null);
                            setPasswordError(null);
                          }
                        }}
                        autoFocus
                        required
                        type="text"
                        placeholder="Username"
                      />
                    </InputGroup>
                    {usernameError && (
                      <Form.Control.Feedback type="invalid">
                        {t(usernameError)}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          isInvalid={passwordError}
                          onChange={(event) => {
                            setPassword(event.target.value);
                            if (event.target.value?.length > 0) {
                              setPasswordError(null);
                              setUserNameError(null);
                            }
                          }}
                          onKeyDown={(event) => {
                            if (event.nativeEvent.code === 'Enter') {
                              handleFormSubmit();
                            }
                          }}
                          required
                          type="password"
                          placeholder="Password"
                        />
                      </InputGroup>
                      {passwordError && (
                        <Form.Control.Feedback type="invalid">
                          {t(passwordError)}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label
                          htmlFor="defaultCheck5"
                          className="mb-0"
                        >
                          Remember me
                        </FormCheck.Label>
                      </Form.Check>
                      <Card.Link className="small text-end">
                        Lost password?
                      </Card.Link>
                    </div>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="button"
                    className="w-100"
                    onClick={handleFormSubmit}
                  >
                    Sign in
                  </Button>

                  {/* <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">or login with</span>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-twitter me-2">
                    <FontAwesomeIcon icon={faTwitter} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pil text-dark">
                    <FontAwesomeIcon icon={faGithub} />
                  </Button>
                </div> */}
                  {/* <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link as={Link} className="fw-bold">
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </StyledLoader>
  );
};
