import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  InputGroup,
  Button,
  Card,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
 
  faUserPlus,
  faRefresh,
  faHourglassStart,
  faFileInvoice,
  faBox,
  faTruckFast, 
  faCircleCheck,
  faPeopleCarry,
  faArrowUp,
  faArrowDown,
  faPenNib,
  faCity,
} from '@fortawesome/free-solid-svg-icons';
// import TableOnly from "../../scheme-management/Components/IndividualScheme.js/TableOnly";
import { useLocation, useNavigate } from "react-router-dom";
import ExportButton from "../orders/components/ExportButton";
import { t } from "i18next";
import DatePicker from "../../tripPayments/DatePicker";
import { apiCall } from "../../../middlewares/api";
import { apiCallConsts } from "../../../constants/apiCallConsts";
import { useDispatch } from "react-redux";
import { updateSpinnerState } from "../../../actions/spinner";
import { updateToastInfo } from "../../../actions/settings";
import EditableDropDown from "../../../components/common/EditableDropdown";
import { setPageNumberInStore } from "../../../actions/tablePage";
import TableOnly from "../../scheme-management/Components/IndividualScheme/TableOnly";

const OrderStatusOverview = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [filterData,setFilterData] = useState({
    startDate : "" , endDate : "" , reportType : "Date"
  })
  const [totalObj , setTotalObj] = useState([])
  const [responseData, setResponseData] = useState({})
  const dispatch = useDispatch()
  const reportTypeArray = [
    { label: 'Region', value: 'Region' },
    { label: 'Date', value: 'Date' }
  ];
  const fetchDate =async () => {
    try{
     
      dispatch(updateSpinnerState(true))
      dispatch(setPageNumberInStore({ id : "Analytics Data", page:1 }));
      const data = await apiCall(apiCallConsts?.GET_METHOD,`/internal/dashboard/overall?startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}&reportType=${filterData?.reportType}`)
      setTableData(data?.dataObj)
      setTotalObj(data?.totalObj)
      setResponseData(data)
      dispatch(updateSpinnerState(false))

    }catch(error)
  {
    dispatch(updateSpinnerState(false));
    dispatch(
      updateToastInfo({
        show: true,
        type: 'danger',
        title: t('Failed Getting Products data'),
        message: t(error.message),
      })
    );
  }
  }

  const handleRefresh = () => {
    try{
      fetchDate()
    }catch(error)
    {
    }
  }

  useEffect((each) => {
    try{
      if(filterData?.startDate && filterData?.endDate)
      {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('startDate', convertDateToDDMMYYYY(filterData?.startDate));
        queryParams.set('endDate', convertDateToDDMMYYYY(filterData?.endDate));
        queryParams.set('reportType', filterData?.reportType);


        navigate(`${location.pathname}?${queryParams.toString()}`);
      fetchDate()
      }

    }catch(error)
    {

    }

  },[])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('startDate')) {
      setFilterData(prev => {
        return {
          ...prev, startDate : new Date(queryParams.get('startDate'))
        }
      });
    }
    if (queryParams.get('endDate')) {
      setFilterData(prev => {
        return {
          ...prev, endDate : new Date(queryParams.get('endDate'))
        }
      });
    }

    if(queryParams.get('reportType'))
    {
      setFilterData(prev => {
        return {
          ...prev, reportType : queryParams.get('reportType')
        }
      });
      
    }else{

      setFilterData(prev => {
        return {
          ...prev, reportType : "Date"
        }
      });

    }
  
  },[])
  const [tableData, setTableData] = useState([
  ])

  

const tableColumns = { 
  Date : [
    { dataField: "date", text: "date", sort: true },
    { 
      dataField: "totalOrders", 
      text: "Total Orders", 
      sort: true,
      formatter: (cell, row) => {
        const apiUrl = `/oms/orders/allOrders?region=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`;
        return (
          <div 
            onClick={() => { window.open(apiUrl, '_blank') }} 
            style={{ cursor: "pointer" }}
          >
            {cell}
          </div>
        );
      } 
    },
    { dataField: "Delivered", text: "Delivered", sort: true  ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/delivered?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    }},
    {
      dataField : "0" , text : "Day 0"
    },
    {
      dataField : "1" , text : "Day 1"
    },
    {
      dataField : "2" , text : "Day 2"
    },
    {
      dataField : "3" , text : "Day 3"
    },
    {
      dataField : "4" , text : "Day 4"
    },
    {
      dataField : "5+" , text : "Day 5+"
    },
    { dataField: "Out For Delivery", text: "Out for Delivery", sort: true  ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/outForDelivery?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    }},
    { dataField: "Picked Up", text: "Shipped", sort: true ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/picked?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    }},
    { dataField: "Ready For Pickup", text: "Ready for Pickup", sort: true ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/ready?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    }},
    { dataField: "Invoiced", text: "Invoiced", sort: true ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/invoice?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    } },
    { dataField: "To Be Invoiced", text: "To be Invoiced", sort: true ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/ManualInvoice?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    } },
    { dataField: "Awaiting Invoice", text: "QC Passed", sort: true ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/awaiting?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    }},
    { dataField: "Accepted", text: "Accepted", sort: true ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/accepted?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    }},
 
    
    { dataField: "Assigned", text: "Assigned", sort: true  , formatter: (cell, row) => {
        const apiUrl = `/oms/orders/assigned?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
        return (
          <div  onClick={() => { window.open(apiUrl, '_blank') }} style={{cursor:"pointer"}}>
            {cell}
          </div>
        );
      }},
   
    
  
   
   
    
    
      { dataField: "Pending Action", text: "Pending Action", sort: true  ,formatter: (cell, row) => {
        const apiUrl = `/oms/orders/pendingAction?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
        return (
          <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
            {cell}
          </div>
        );
      }},
      { dataField: "Cancelled", text: "Cancelled", sort: true  ,formatter: (cell, row) => {
        const apiUrl = `/oms/orders/cancelled?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
        return (
          <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
            {cell}
          </div>
        );
      }},
      { dataField: "Out Of Stock", text: "Out Of Stock", sort: true  ,formatter: (cell, row) => {
        const apiUrl = `/oms/orders/outOfStock?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
        return (
          <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
            {cell}
          </div>
        );
      }},
      { dataField: "Refund Initiated", text: "Refund Initiated", sort: true  ,formatter: (cell, row) => {
        const apiUrl = `/oms/orders/Refund Initiated?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
        return (
          <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
            {cell}
          </div>
        );
      }},
       { dataField: "Refunded", text: "Refunded", sort: true  ,formatter: (cell, row) => {
        const apiUrl = `/oms/orders/Refunded?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
        return (
          <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
            {cell}
          </div>
        );
      }},
      { 
        dataField: "revenue", 
        text: "Revenue", 
        sort: true, 
        formatter: (cell, row) => {
          // Format the number as Indian Rupees
          const formattedRevenue = new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "INR"
          }).format(cell);
      
          return (
            <div>
              {formattedRevenue}
            </div>
          );
        }
      }
      ,

     
  ],
Region :  [
  { dataField: "cityName", text: "city", sort: true },
  { 
    dataField: "totalOrders", 
    text: "Total Orders", 
    sort: true,
    formatter: (cell, row) => {
      const apiUrl = `/oms/orders/allOrders?region=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`;
      return (
        <div 
          onClick={() => { window.open(apiUrl, '_blank') }} 
          style={{ cursor: "pointer" }}
        >
          {cell}
        </div>
      );
    } 
  },
  
  { dataField: "Assigned", text: "Assigned", sort: true  , formatter: (cell, row) => {
      const apiUrl = `/oms/orders/assigned?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }} style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    }},
  { dataField: "Accepted", text: "Accepted", sort: true ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/accepted?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    }},
  { dataField: "Awaiting Invoice", text: "QC Passed", sort: true ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/awaiting?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    }},
  { dataField: "To Be Invoiced", text: "To be Invoiced", sort: true ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/ManualInvoice?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    } },
  { dataField: "Invoiced", text: "Invoiced", sort: true ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/invoice?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    } },
  { dataField: "Ready For Pickup", text: "Ready for Pickup", sort: true ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/ready?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    }},
  { dataField: "Picked Up", text: "Shipped", sort: true ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/picked?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    }},
  { dataField: "Out For Delivery", text: "Out for Delivery", sort: true  ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/outForDelivery?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    }},
    { dataField: "Delivered", text: "Delivered", sort: true  ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/delivered?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    }},
    { dataField: "Pending Action", text: "Pending Action", sort: true  ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/pendingAction?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    }},
    { dataField: "Cancelled", text: "Cancelled", sort: true  ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/cancelled?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    }},
    { dataField: "Refund Initiated", text: "Refund Initiated", sort: true  ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/Refund Initiated?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    }}, { dataField: "Refunded", text: "Refunded", sort: true  ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/Refunded?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    }},
    { dataField: "Out Of Stock", text: "Out Of Stock", sort: true  ,formatter: (cell, row) => {
      const apiUrl = `/oms/orders/outOfStock?zone=${row.cityName}&startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`
      return (
        <div  onClick={() => { window.open(apiUrl, '_blank') }}  style={{cursor:"pointer"}}>
          {cell}
        </div>
      );
    }},
]}

  return (
    <div className="h-100 w-100" style={{ minHeight: "100vh", padding: "16px", backgroundColor:"#F5F8FB"}}>
      <Container fluid className="px-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2 className="h5 mb-0">Order Status Overview</h2>
          <div style={{display:"flex",justifyContent:"space-between" , width:"17%"}}>
          <Button
                      variant="white"
                      onClick={() => {
                        handleRefresh()
                      }}
                      style={{
                        width: '150px',
                        height: '38px',
                        borderRadius: 0,
                      }}
                      size="sm"
                    >
                      <FontAwesomeIcon icon={faRefresh} />
                      &nbsp;&nbsp;{t('Refresh')}
                    </Button>
                    <ExportButton
                      className="btn-export"
                      data={tableData}
                      columns={
                        filterData?.reportType != "Region" ?
                        [
                        {
                          field: 'Date',
                          key: 'date',
                        },{
                          field: 'totalOrders',
                          key: 'totalOrders',
                        },
                        { field: 'Delivered', key: 'Delivered' },
                        {
                          field : "Day 0" , key : "0"
                        },
                        {
                          field : "Day 1" , key : "1"
                        },
                        {
                          field : "Day 2" , key : "2"
                        },
                        {
                          field : "Day 3" , key : "3"
                        },
                        {
                          field : "Day 4" , key : "4"
                        },
                        {
                          field : "Day 5+" , key : "5+"
                        },
                        { field: 'Out For Delivery', key: 'Out For Delivery' },
                        { field: 'Picked Up', key: 'Picked Up' },
                        { field: 'Ready For Pickup', key: 'Ready For Pickup' },
                        { field: 'Invoiced', key: 'Invoiced' },
                        { field: 'To Be Invoiced', key: 'Awaiting Invoice' },
                        { field: 'Awaiting Invoice', key: 'Awaiting Invoice' },
                        { field: 'Accepted', key: 'Accepted' },
                        {
                          field: 'Assigned',
                          key: 'Assigned',
                        },{
                          field: 'Pending Action',
                          key: 'Pending Action',
                        },
                        { field: 'Cancelled', key: 'Cancelled' } , 
                        { field: 'Out Of Stock', key: 'Out Of Stock' },
                        { field: 'Refund Initiated', key: 'Refund Initiated' },
                        {
                          field: 'Refunded',
                          key: 'Refunded',
                        },{
                          field : "Revenue",
                          key : "revenue"
                        }
                      ] :  [
                        {
                          field: 'cityName',
                          key: 'cityName',
                        },
                        {
                          field: 'Assigned',
                          key: 'Assigned',
                        },
                        { field: 'Accepted', key: 'Accepted' },
                        { field: 'Awaiting Invoice', key: 'Awaiting Invoice' },
                        { field: 'To Be Invoiced', key: 'Awaiting Invoice' },
                        { field: 'Invoiced', key: 'Invoiced' },
                        { field: 'Ready For Pickup', key: 'Ready For Pickup' },
                        { field: 'Out For Delivery', key: 'Out For Delivery' },
                        { field: 'Delivered', key: 'Delivered' },
                        { field: 'Out Of Stock', key: 'Out Of Stock' },
                        { field: 'Refund Initiated', key: 'Refund Initiated' },
                        {
                          field: 'Refunded',
                          key: 'Refunded',
                        },
                        {
                          field: 'Pending Action',
                          key: 'Pending Action',
                        },
                        { field: 'Cancelled', key: 'Cancelled' }  
                      ]}
                      fileName={filterData?.reportType}
                    />
          </div>
        </div>

        <Row className="g-3 mb-3">
          <Col md={4}>
            <Card className="h-100 border-0 shadow-sm">
              <Card.Body className="d-flex align-items-center justify-content-between">
                <div>
                  <div className="text-muted small">Total {filterData?.reportType}</div>
                  <div className="h4 mb-0">{responseData?.total}</div>
                </div>
                <FontAwesomeIcon icon={faCity} className="text-primary h4 mb-0" />
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="h-100 border-0 shadow-sm">
              <Card.Body className="d-flex align-items-center justify-content-between">
                <div>
                  <div className="text-muted small">Top {filterData?.reportType}</div>
                  <div className="h4 mb-0">{responseData?.top || ""}</div>
                </div>
                <FontAwesomeIcon icon={faArrowUp} className="text-success h4 mb-0" />
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="h-100 border-0 shadow-sm">
              <Card.Body className="d-flex align-items-center justify-content-between">
                <div>
                  <div className="text-muted small">Lowest {filterData?.reportType}</div>
                  <div className="h4 mb-0">{responseData?.bottom|| ""}</div>
                </div>
                <FontAwesomeIcon icon={faArrowDown} className="text-danger h4 mb-0" />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <div className="mb-3  p-3 ">
          <div className="d-flex gap-3 " >
            {[
              { label: "Assigned", value: totalObj["Assigned"] || 0 , icon : faUserPlus , url:`/oms/orders/assigned?startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`},
              { label: "Accepted", value: totalObj["Accepted"] || 0, icon : faCircleCheck, url:`/oms/orders/accepted?startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`},
              { label: "QC Passed", value: totalObj["Awaiting Invoice"] || 0, icon :faHourglassStart , url :  `/oms/orders/awaiting?startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`},
              { label: "To be Invoiced", value: totalObj["To Be Invoiced"] || 0, icon : faPenNib , url : `/oms/orders/ManualInvoice?startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`},
              { label: "Invoiced", value: totalObj["Invoiced"] || 0 , icon : faFileInvoice ,url : `/oms/orders/invoice?startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`},
              { label: "Ready for Pickup", value: totalObj["Ready For Pickup"] || 0, icon : faBox,url : `/oms/orders/ready?startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`},
              { label: "Shipped", value: totalObj["Picked up"] || 0 , icon : faTruckFast,url : `/oms/orders/picked?startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}` },
              { label: "Out for Delivery", value: totalObj["Out For Delivery"] || 0 , icon :faPeopleCarry,url : `/oms/orders/outForDelivery?startDate=${convertDateToDDMMYYYY(filterData?.startDate)}&endDate=${convertDateToDDMMYYYY(filterData?.endDate)}`}
            ].map((item, index) => (
              <div key={index} className="px-3 py-2 border bg-white flex-shrink-0"  onClick={() => { window.open(item?.url, '_blank') }}  style={{ minWidth: "100px" , cursor:"pointer"}}>
                <small className="text-muted">
                  <FontAwesomeIcon icon={item?.icon} className="me-2" />
                  {item.label}:
                </small>
                <span className="ms-1 text-primary">{item.value}</span>
              </div>
            ))}
          </div>
        </div>

        <Card className="border-0 shadow-sm">
          <Card.Body>
            <Row className="mb-4 align-items-center">
            <Col lg={9} className="d-flex gap-2 align-items-center">


              <div>
              <EditableDropDown
                id={'productType'}
                onChange={(e) => {

                  setTableData([])
                  setFilterData((prev) => {
                    return {
                      ...prev,
                      reportType : e.value,
                    };
                  });
                }}
                style={{
                  height: 38,
                  width: '100%',
                }}
                value={filterData?.reportType}
                options={reportTypeArray}
                placeholder={'Choose the Product Type'}
              />
            </div>
              <div>
               
                <DatePicker
                  value={filterData?.startDate}
                  backgroundColor="white"
                  placeholder={"Start Date: dd/mm/yyyy"}
                  setValue={(date) => {
                    setFilterData((prev) => {
                      return {
                        ...prev,
                        startDate: date,
                      };
                    });
                  }}
                />
              </div>

              <div>
                
                <DatePicker
                  value={filterData?.endDate}
                  backgroundColor="white"
                  placeholder={"End Date: dd/mm/yyyy"}
                  setValue={(date) => {
                    setFilterData((prev) => {
                      return {
                        ...prev,
                        endDate: date,
                      };
                    });
                  }}
                />
              </div>

             
              <Button
                      variant={'white'}
                      onClick={() => {
                        fetchDate()
                      }}
                      style={{
                        width: '150px',
                        height: '38px',
                        borderRadius: 0,
                        border: '1px solid #262B40',
                      }}
                      size="sm"
                    >submit</Button>
             
              </Col>

            </Row>

            <TableOnly
              id="Analytics Data"
              data={tableData}
              columns={tableColumns[filterData?.reportType]}
            />
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default OrderStatusOverview;


function convertDateToDDMMYYYY(dateString) {
  // Create a Date object from the input string
  const date = new Date(dateString);

  // Get day, month, and year components
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();

  // Format the date as dd/mm/yyyy
  return `${year}-${month}-${day}`;
}