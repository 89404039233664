import React, { useEffect, useState } from 'react';
import DatePicker from '../../../../components/common/DatePicker';
import EditableDropDown from '../../../../components/common/EditableDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { setSchemeOptionsInStore } from '../../../../actions/sms/schemeOptions';
import { updateSpinnerState } from '../../../../actions/spinner';
import { getAllCompaniesCategoriesSubCategoriesData } from '../../../../parse-functions/sms/scheme';
import { arrayToDropdownOptions } from '../../../../utils/sms/arrayToDropdownOptions';
import { updateToastInfo } from '../../../../actions/settings';
import { useTranslation } from 'react-i18next';
import { Button, Form } from '@themesberg/react-bootstrap';
import {
  faArrowRotateLeft,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SchemeFilters = ({ onSubmit }) => {
  const { t } = useTranslation();
  const initialFormInput = {
    fromDate: '',
    toDate: '',
    company: '',
  };
  const [formInput, setFormInput] = useState({});
  const schemeCompanies = useSelector(
    (state) => state?.schemeOptions?.schemeCompanies || []
  );
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const userInfo = useSelector((state) => state?.user?.userInfo);
  const dispatch = useDispatch();

  const onFilterSubmit = () => {
    onSubmit(formInput.fromDate, formInput.toDate, formInput.company);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(updateSpinnerState(true));
        let brandOptions = [];
        if (isAdmin) {
          brandOptions = await getAllCompaniesCategoriesSubCategoriesData({
            isCompany: true,
          });
          dispatch(
            setSchemeOptionsInStore({
              type: 'schemeCompanies',
              data: brandOptions?.map((item) => {
                return { label: item.companyName, value: item.companyName };
              }),
            })
          );
          dispatch(updateSpinnerState(false));
        } else {
          brandOptions = userInfo?.company?.filter((item) => item !== 'All');
          dispatch(
            setSchemeOptionsInStore({
              type: 'schemeCompanies',
              data: arrayToDropdownOptions(brandOptions),
            })
          );
        }
      } catch (error) {
        dispatch(updateSpinnerState(false));
        console.log(error);
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: '',
            message: t(`Error while fetching companies.`),
          })
        );
      }
    };
    if (schemeCompanies.length === 0) {
      fetchData();
    }
  }, [userInfo]);

  return (
    <>
      <div
        className="table-settings mt-4 mb-4 p-3"
        style={{
          background: 'white',
          transition: 'max-height 0.7s ease-in-out',
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '20px',
            width: '100%',
            backgroundColor: 'white',
          }}
        >
          <Form.Group>
            <Form.Label>From Date</Form.Label>
            <DatePicker
              value={formInput?.fromDate}
              backgroundColor="white"
              setValue={(date) => {
                setFormInput((prev) => {
                  return {
                    ...prev,
                    fromDate: date,
                  };
                });
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>To Date</Form.Label>
            <DatePicker
              value={formInput?.toDate}
              backgroundColor="white"
              setValue={(date) => {
                setFormInput((prev) => {
                  return {
                    ...prev,
                    toDate: date,
                  };
                });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>{t('Company Name')}</Form.Label>
            <EditableDropDown
              id={'company'}
              onChange={(e) =>
                setFormInput((prev) => ({
                  ...prev,
                  company: [e.value],
                }))
              }
              value={formInput['company']}
              options={schemeCompanies}
              placeholder={'Choose Company Name'}
              backgroundColor={'#F0F0F0'}
            ></EditableDropDown>
          </Form.Group>
        </div>
        <div
          style={{
            gridColumn: 'span 3',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
            marginTop: '20px',
          }}
        >
          <Button
            variant="white"
            onClick={() => setFormInput(initialFormInput)}
            style={{
              width: '150px',
              height: '38px',
              borderRadius: 0,
              background: '#EAEDF2',
            }}
          >
            <FontAwesomeIcon icon={faArrowRotateLeft} />
            &nbsp;&nbsp;{t('Reset')}
          </Button>
          <Button
            variant="white"
            className="fa-export"
            onClick={onFilterSubmit}
            style={{
              width: '150px',
              height: '38px',
              borderRadius: 0,
              border: '1px solid #262B40',
            }}
          >
            <FontAwesomeIcon icon={faSliders} />
            &nbsp;&nbsp;{t('Apply Filter')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SchemeFilters;
